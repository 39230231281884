var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "inFormation df" },
    [
      _c("div", { staticClass: "main-left" }, [
        _c("div", { staticClass: "photo-box" }, [
          _c("img", {
            attrs: {
              src: "https://crmapi.ternarysystem.com/storage/client/teacher/img/20210407/a55c0a2be09adc0df6c564a7c4fbac6b.png",
              alt: "",
            },
          }),
          _vm._v(" "),
          _c(
            "p",
            {
              staticClass: "Pname",
              staticStyle: { color: "#000", "margin-bottom": "0" },
            },
            [_vm._v(_vm._s(_vm.detailForm.name))]
          ),
          _vm._v(" "),
          _c(
            "p",
            {
              staticClass: "Pname",
              staticStyle: { color: "#888", "margin-top": "10px" },
            },
            [_vm._v(_vm._s(_vm.detailForm.Telephone))]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "df bte" }, [
            _c("div", { staticClass: "pct50 bre" }, [
              _c("p", { staticClass: "f16" }, [
                _vm._v(_vm._s(_vm.sum_money) + "元"),
              ]),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass: "f14 money-text blueFont",
                  staticStyle: { height: "20px", "line-height": "20px" },
                },
                [_vm._v("成交金额")]
              ),
            ]),
            _vm._v(" "),
            _vm._m(0),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "bgwh mb20 p20" }, [
          _vm.enteredBy != ""
            ? _c("div", [
                _c("div", { staticClass: "g3 mt5 mb15" }, [
                  _vm._v("录入人：" + _vm._s(_vm.enteredBy.realname)),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "g9 mb15" }, [
                  _vm._v("录入时间：" + _vm._s(_vm.enteredBy.create_time)),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "bbe pb10" }, [_vm._v("操作日志")]),
          _vm._v(" "),
          _vm.isusertype
            ? _c(
                "div",
                { staticClass: "ova" },
                _vm._l(_vm.journal, function (item, index) {
                  return _c("div", { key: index, staticClass: "f14 bbe" }, [
                    _c("div", { staticClass: "g3 mt5 mt15 mb15" }, [
                      _vm._v(_vm._s(item.note)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "g9 mb15" }, [
                      _vm._v(_vm._s(item.create_time)),
                    ]),
                  ])
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt20 tc" },
            [
              _c(
                "el-button",
                {
                  staticClass: "asasa",
                  attrs: { type: "goon", plain: "", round: "" },
                  on: { click: _vm.switchs },
                },
                [_vm._v(_vm._s(_vm.isusertypeName))]
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "main-right", staticStyle: { position: "relative" } },
        [
          _c(
            "el-tabs",
            {
              attrs: { type: "border-card" },
              on: { "tab-click": _vm.handleClick },
            },
            [
              _c("el-tab-pane", { attrs: { label: "客户信息" } }, [
                _c("div", { staticClass: "fl tabLeft" }, [
                  _c(
                    "div",
                    { staticClass: "RequiredInfosrmation" },
                    [
                      _c("h3", [_vm._v("基本信息")]),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            {
                              attrs: { xs: 24, sm: 24, md: 24, lg: 12, xl: 12 },
                            },
                            [
                              _c("ul", [
                                _c(
                                  "li",
                                  {
                                    staticStyle: {
                                      "border-right-width": "0px",
                                    },
                                    on: {
                                      mouseenter: function ($event) {
                                        return _vm.changeShowValue(
                                          "cname",
                                          true
                                        )
                                      },
                                      mouseleave: function ($event) {
                                        return _vm.changeShowValue(
                                          "cname",
                                          false
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "textAlign",
                                        staticStyle: { width: "22%" },
                                      },
                                      [_vm._v("客户名称：")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.detailFormShow.cname,
                                            expression: "detailFormShow.cname",
                                          },
                                        ],
                                        staticStyle: { width: "76.6666%" },
                                      },
                                      [_vm._v(_vm._s(_vm.detailForm.name))]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "input-show",
                                        staticStyle: { width: "76.6666%" },
                                      },
                                      [
                                        _c("el-input", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: !_vm.detailFormShow.cname,
                                              expression:
                                                "!detailFormShow.cname",
                                            },
                                          ],
                                          staticClass: "input-style",
                                          attrs: {
                                            placeholder: "请输入内容",
                                            disabled: "",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.dblEditCustomer()
                                            },
                                          },
                                          model: {
                                            value: _vm.detailForm.name,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.detailForm,
                                                "name",
                                                $$v
                                              )
                                            },
                                            expression: "detailForm.name",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "li",
                                  {
                                    on: {
                                      mouseenter: function ($event) {
                                        return _vm.changeShowValue(
                                          "cphone",
                                          true
                                        )
                                      },
                                      mouseleave: function ($event) {
                                        return _vm.changeShowValue(
                                          "cphone",
                                          false
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "textAlign",
                                        staticStyle: { width: "22%" },
                                      },
                                      [_vm._v("电话：")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.detailFormShow.cphone,
                                            expression: "detailFormShow.cphone",
                                          },
                                        ],
                                        staticStyle: {
                                          width: "76.6666%",
                                          position: "relative",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                            " +
                                            _vm._s(_vm.detailForm.Telephone) +
                                            "\n                                            "
                                        ),
                                        _c("span", {
                                          staticStyle: {
                                            "padding-left": "15px",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-button", {
                                          staticStyle: {
                                            position: "absolute",
                                            right: "6px",
                                            top: "6px",
                                            padding: "4px 6px",
                                          },
                                          attrs: {
                                            type: "primary",
                                            icon: "el-icon-plus",
                                            disabled: "",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !_vm.detailFormShow.cphone,
                                            expression:
                                              "!detailFormShow.cphone",
                                          },
                                        ],
                                        staticClass: "input-show",
                                        staticStyle: { width: "76.6666%" },
                                      },
                                      [
                                        _c("el-input", {
                                          staticStyle: {
                                            width: "51%",
                                            border: "none",
                                            "padding-left": "0",
                                            "background-color": "#fff",
                                          },
                                          attrs: {
                                            disabled: _vm.disable,
                                            placeholder: "请输入内容",
                                            oninput: (_vm.detailForm.Telephone =
                                              _vm.detailForm.Telephone),
                                          },
                                          on: {
                                            blur: function ($event) {
                                              return _vm.dblEditCustomer()
                                            },
                                            change: function ($event) {
                                              return _vm.GETPlaceofownership(
                                                _vm.detailForm.Telephone,
                                                1
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.detailForm.Telephone,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.detailForm,
                                                "Telephone",
                                                $$v
                                              )
                                            },
                                            expression: "detailForm.Telephone",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-input", {
                                          staticStyle: {
                                            width: "36%",
                                            "padding-left": "5px",
                                            border: "none",
                                          },
                                          attrs: { disabled: true },
                                          model: {
                                            value: _vm.detailForm.cphoneAdress,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.detailForm,
                                                "cphoneAdress",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "detailForm.cphoneAdress",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("el-button", {
                                          staticStyle: {
                                            padding: "11px 14px",
                                            "margin-left": "5px",
                                          },
                                          attrs: {
                                            type: "primary",
                                            icon: "el-icon-plus",
                                            disabled: "",
                                          },
                                          on: { click: _vm.displayShow },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.show2
                                  ? _c(
                                      "li",
                                      {
                                        on: {
                                          mouseenter: function ($event) {
                                            return _vm.changeShowValue(
                                              "cphonetwo",
                                              true
                                            )
                                          },
                                          mouseleave: function ($event) {
                                            return _vm.changeShowValue(
                                              "cphonetwo",
                                              false
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "textAlign",
                                            staticStyle: { width: "22%" },
                                          },
                                          [_vm._v("联系电话2：")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.detailFormShow.cphonetwo,
                                                expression:
                                                  "detailFormShow.cphonetwo",
                                              },
                                            ],
                                            staticStyle: {
                                              width: "76.6666%",
                                              position: "relative",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                            " +
                                                _vm._s(
                                                  _vm.detailForm.Telephone2
                                                ) +
                                                "\n                                            "
                                            ),
                                            _c("el-button", {
                                              staticStyle: {
                                                position: "absolute",
                                                right: "6px",
                                                top: "6px",
                                                padding: "4px 6px",
                                              },
                                              attrs: {
                                                type: "primary",
                                                icon: "el-icon-minus",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  !_vm.detailFormShow.cphonetwo,
                                                expression:
                                                  "!detailFormShow.cphonetwo",
                                              },
                                            ],
                                            staticClass: "input-show",
                                            staticStyle: { width: "76.6666%" },
                                          },
                                          [
                                            _c("el-input", {
                                              staticStyle: { width: "39%" },
                                              attrs: {
                                                oninput:
                                                  (_vm.detailForm.Telephone2 =
                                                    _vm.detailForm.Telephone2),
                                                disabled: _vm.mo1disable,
                                                placeholder: "请输入内容",
                                              },
                                              on: {
                                                blur: function ($event) {
                                                  return _vm.dblEditCustomer()
                                                },
                                                change: function ($event) {
                                                  return _vm.GETPlaceofownership(
                                                    _vm.detailForm.Telephone2,
                                                    2
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.detailForm.Telephone2,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.detailForm,
                                                    "Telephone2",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "detailForm.Telephone2",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("el-input", {
                                              staticStyle: {
                                                width: "36%",
                                                border: "none",
                                                "padding-left": "5px",
                                              },
                                              attrs: { disabled: true },
                                              model: {
                                                value:
                                                  _vm.detailForm.cphoneAdress2,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.detailForm,
                                                    "cphoneAdress2",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "detailForm.cphoneAdress2",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("el-button", {
                                              staticStyle: {
                                                padding: "11px 14px",
                                                "margin-left": "5px",
                                              },
                                              attrs: {
                                                type: "primary",
                                                icon: "el-icon-minus",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.mobilePhone(2)
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.show3
                                  ? _c(
                                      "li",
                                      {
                                        on: {
                                          mouseenter: function ($event) {
                                            return _vm.changeShowValue(
                                              "cphonethree",
                                              true
                                            )
                                          },
                                          mouseleave: function ($event) {
                                            return _vm.changeShowValue(
                                              "cphonethree",
                                              false
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "textAlign",
                                            staticStyle: { width: "22%" },
                                          },
                                          [_vm._v("联系电话3：")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.detailFormShow
                                                    .cphonethree,
                                                expression:
                                                  "detailFormShow.cphonethree",
                                              },
                                            ],
                                            staticStyle: {
                                              width: "76.6666%",
                                              position: "relative",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                            " +
                                                _vm._s(
                                                  _vm.detailForm.Telephone3
                                                ) +
                                                "\n                                            "
                                            ),
                                            _c("el-button", {
                                              staticStyle: {
                                                position: "absolute",
                                                right: "6px",
                                                top: "6px",
                                                padding: "4px 6px",
                                              },
                                              attrs: {
                                                type: "primary",
                                                icon: "el-icon-minus",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  !_vm.detailFormShow
                                                    .cphonethree,
                                                expression:
                                                  "!detailFormShow.cphonethree",
                                              },
                                            ],
                                            staticClass: "input-show",
                                            staticStyle: { width: "76.6666%" },
                                          },
                                          [
                                            _c("el-input", {
                                              staticStyle: { width: "39%" },
                                              attrs: {
                                                oninput:
                                                  (_vm.detailForm.Telephone3 =
                                                    _vm.detailForm.Telephone3.replace(
                                                      /[^\d]/g,
                                                      ""
                                                    )),
                                                disabled: _vm.mo2disable,
                                                placeholder: "请输入内容",
                                              },
                                              on: {
                                                blur: function ($event) {
                                                  return _vm.dblEditCustomer()
                                                },
                                                change: function ($event) {
                                                  return _vm.GETPlaceofownership(
                                                    _vm.detailForm.Telephone3,
                                                    3
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.detailForm.Telephone3,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.detailForm,
                                                    "Telephone3",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "detailForm.Telephone3",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("el-input", {
                                              staticStyle: {
                                                width: "32%",
                                                border: "none",
                                                "padding-left": "5px",
                                              },
                                              attrs: { disabled: true },
                                              model: {
                                                value:
                                                  _vm.detailForm.cphoneAdress3,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.detailForm,
                                                    "cphoneAdress3",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "detailForm.cphoneAdress3",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("el-button", {
                                              staticStyle: {
                                                padding: "11px 14px",
                                                "margin-left": "5px",
                                              },
                                              attrs: {
                                                type: "primary",
                                                icon: "el-icon-minus",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.mobilePhone(3)
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "li",
                                  {
                                    on: {
                                      mouseenter: function ($event) {
                                        return _vm.changeShowValue(
                                          "cemail",
                                          true
                                        )
                                      },
                                      mouseleave: function ($event) {
                                        return _vm.changeShowValue(
                                          "cemail",
                                          false
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "textAlign",
                                        staticStyle: { width: "22%" },
                                      },
                                      [_vm._v("邮箱：")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.detailFormShow.cemail,
                                            expression: "detailFormShow.cemail",
                                          },
                                        ],
                                        staticStyle: { width: "76.6666%" },
                                      },
                                      [_vm._v(_vm._s(_vm.detailForm.mailbox))]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "input-show",
                                        staticStyle: { width: "76.6666%" },
                                      },
                                      [
                                        _c("el-input", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: !_vm.detailFormShow.cemail,
                                              expression:
                                                "!detailFormShow.cemail",
                                            },
                                          ],
                                          staticClass: "input-style",
                                          staticStyle: { "padding-left": "0" },
                                          attrs: {
                                            placeholder: "请输入内容",
                                            disabled: "",
                                          },
                                          on: {
                                            blur: function ($event) {
                                              return _vm.dblEditCustomer()
                                            },
                                            change: function ($event) {
                                              return _vm.sendEmail()
                                            },
                                          },
                                          model: {
                                            value: _vm.detailForm.mailbox,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.detailForm,
                                                "mailbox",
                                                $$v
                                              )
                                            },
                                            expression: "detailForm.mailbox",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "li",
                                  {
                                    staticStyle: {
                                      "border-right-width": "0px",
                                    },
                                    on: {
                                      mouseenter: function ($event) {
                                        return _vm.changeShowValue(
                                          "communication_name",
                                          true
                                        )
                                      },
                                      mouseleave: function ($event) {
                                        return _vm.changeShowValue(
                                          "communication_name",
                                          false
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "textAlign",
                                        staticStyle: { width: "22%" },
                                      },
                                      [_vm._v("推广项目：")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.detailFormShow
                                                .communication_name,
                                            expression:
                                              "detailFormShow.communication_name",
                                          },
                                        ],
                                        staticStyle: { width: "76.6666%" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.detailForm.extensionName)
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "input-show",
                                        staticStyle: { width: "76.6666%" },
                                      },
                                      [
                                        _c("el-input", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                !_vm.detailFormShow
                                                  .communication_name,
                                              expression:
                                                "!detailFormShow.communication_name",
                                            },
                                          ],
                                          staticClass: "input-style",
                                          attrs: {
                                            placeholder: "请输入内容",
                                            disabled: "",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.dblEditCustomer()
                                            },
                                          },
                                          model: {
                                            value: _vm.detailForm.extensionName,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.detailForm,
                                                "extensionName",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "detailForm.extensionName",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              attrs: { xs: 24, sm: 24, md: 24, lg: 12, xl: 12 },
                            },
                            [
                              _c("ul", [
                                _c(
                                  "li",
                                  {
                                    on: {
                                      mouseenter: function ($event) {
                                        return _vm.changeShowValue(
                                          "agent_name",
                                          true
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticStyle: { width: "22%" } },
                                      [_vm._v("学员来源(花名)：")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.detailFormShow.agent_name,
                                            expression:
                                              "detailFormShow.agent_name",
                                          },
                                        ],
                                        staticStyle: { width: "76.6666%" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.detailForm.flower_name)
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              !_vm.detailFormShow.agent_name,
                                            expression:
                                              "!detailFormShow.agent_name",
                                          },
                                        ],
                                        staticStyle: { width: "76.6666%" },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            directives: [
                                              {
                                                name: "loadmore",
                                                rawName: "v-loadmore",
                                                value: _vm.flowerName,
                                                expression: "flowerName",
                                              },
                                            ],
                                            staticStyle: { width: "70%" },
                                            attrs: {
                                              filterable: "",
                                              remote: "",
                                              "reserve-keyword": "",
                                              placeholder: "请搜索花名",
                                              "remote-method":
                                                _vm.flowerArrayEs,
                                              disabled: _vm.disable,
                                              loading: _vm.loading,
                                            },
                                            on: {
                                              "visible-change": _vm.flower,
                                            },
                                            model: {
                                              value:
                                                _vm.detailForm.flowerNameId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.detailForm,
                                                  "flowerNameId",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "detailForm.flowerNameId",
                                            },
                                          },
                                          _vm._l(
                                            _vm.flower_Array,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.id,
                                                attrs: {
                                                  label: item.flower_name,
                                                  value: item.id,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("el-input", {
                                          staticStyle: {
                                            width: "30%",
                                            "padding-left": "0",
                                            border: "none",
                                          },
                                          attrs: {
                                            disabled: "",
                                            placeholder: "类别",
                                          },
                                          model: {
                                            value: _vm.detailForm.category,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.detailForm,
                                                "category",
                                                $$v
                                              )
                                            },
                                            expression: "detailForm.category",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "li",
                                  {
                                    staticStyle: {
                                      "border-right-width": "0px",
                                    },
                                    on: {
                                      mouseenter: function ($event) {
                                        return _vm.changeShowValue(
                                          "wxnum",
                                          true
                                        )
                                      },
                                      mouseleave: function ($event) {
                                        return _vm.changeShowValue(
                                          "wxnum",
                                          false
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "textAlign",
                                        staticStyle: { width: "22%" },
                                      },
                                      [_vm._v("微信：")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.detailFormShow.wxnum,
                                            expression: "detailFormShow.wxnum",
                                          },
                                        ],
                                        staticStyle: { width: "76.6666%" },
                                      },
                                      [_vm._v(_vm._s(_vm.detailForm.WeChat))]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "input-show",
                                        staticStyle: { width: "76.6666%" },
                                      },
                                      [
                                        _c("el-input", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: !_vm.detailFormShow.wxnum,
                                              expression:
                                                "!detailFormShow.wxnum",
                                            },
                                          ],
                                          staticClass: "input-style",
                                          attrs: {
                                            placeholder: "请输入内容",
                                            disabled: _vm.wxdisable,
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.dblEditCustomer()
                                            },
                                          },
                                          model: {
                                            value: _vm.detailForm.WeChat,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.detailForm,
                                                "WeChat",
                                                $$v
                                              )
                                            },
                                            expression: "detailForm.WeChat",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "li",
                                  {
                                    on: {
                                      mouseenter: function ($event) {
                                        return _vm.changeShowValue("qq", true)
                                      },
                                      mouseleave: function ($event) {
                                        return _vm.changeShowValue("qq", false)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "textAlign",
                                        staticStyle: { width: "22%" },
                                      },
                                      [_vm._v("QQ：")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.detailFormShow.qq,
                                            expression: "detailFormShow.qq",
                                          },
                                        ],
                                        staticStyle: { width: "76.6666%" },
                                      },
                                      [_vm._v(_vm._s(_vm.detailForm.QQ))]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "input-show",
                                        staticStyle: { width: "76.6666%" },
                                      },
                                      [
                                        _c("el-input", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: !_vm.detailFormShow.qq,
                                              expression: "!detailFormShow.qq",
                                            },
                                          ],
                                          staticClass: "input-style",
                                          attrs: {
                                            placeholder: "请输入内容",
                                            disabled: "",
                                          },
                                          on: {
                                            blur: function ($event) {
                                              return _vm.dblEditCustomer()
                                            },
                                            change: function ($event) {
                                              return _vm.QQverification(
                                                _vm.detailForm.QQ
                                              )
                                            },
                                          },
                                          model: {
                                            value: _vm.detailForm.QQ,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.detailForm,
                                                "QQ",
                                                $$v
                                              )
                                            },
                                            expression: "detailForm.QQ",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "li",
                                  {
                                    staticStyle: {
                                      "border-right-width": "0px",
                                    },
                                    on: {
                                      mouseenter: function ($event) {
                                        return _vm.changeShowValue(
                                          "communication_website",
                                          true
                                        )
                                      },
                                      mouseleave: function ($event) {
                                        return _vm.changeShowValue(
                                          "communication_website",
                                          false
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "textAlign",
                                        staticStyle: { width: "22%" },
                                      },
                                      [_vm._v("推广地址：")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.detailFormShow
                                                .communication_website,
                                            expression:
                                              "detailFormShow.communication_website",
                                          },
                                        ],
                                        staticStyle: { width: "76.6666%" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.detailForm.extensionAddress
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "input-show",
                                        staticStyle: { width: "76.6666%" },
                                      },
                                      [
                                        _c("el-input", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                !_vm.detailFormShow
                                                  .communication_website,
                                              expression:
                                                "!detailFormShow.communication_website",
                                            },
                                          ],
                                          staticClass: "input-style",
                                          attrs: {
                                            placeholder: "请输入内容",
                                            disabled: "",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.dblEditCustomer()
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm.detailForm.extensionAddress,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.detailForm,
                                                "extensionAddress",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "detailForm.extensionAddress",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            {
                              attrs: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 },
                            },
                            [
                              _c("ul", [
                                _c(
                                  "li",
                                  {
                                    staticStyle: {
                                      width: "100%",
                                      "border-bottom-width": "1px",
                                    },
                                    on: {
                                      mouseenter: function ($event) {
                                        return _vm.changeShowValue(
                                          "noteAppEs",
                                          true
                                        )
                                      },
                                      mouseleave: function ($event) {
                                        return _vm.changeShowValue(
                                          "noteAppEs",
                                          false
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "textAlign",
                                        staticStyle: {
                                          width: "11%",
                                          height: "40px",
                                        },
                                      },
                                      [_vm._v("创建附注：")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.detailFormShow.noteAppEs,
                                            expression:
                                              "detailFormShow.noteAppEs",
                                          },
                                        ],
                                        staticStyle: {
                                          width: "88%",
                                          height: "40px",
                                          "line-height": "40px",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.detailForm.appended))]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              !_vm.detailFormShow.noteAppEs,
                                            expression:
                                              "!detailFormShow.noteAppEs",
                                          },
                                        ],
                                        staticClass: "input-show",
                                        staticStyle: {
                                          width: "89%",
                                          height: "40px",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "input-style textarea",
                                          staticStyle: {
                                            width: "100%",
                                            height: "40px",
                                            "line-height": "40px",
                                            border: "none",
                                            "background-color": "#fff",
                                          },
                                          attrs: {
                                            type: "textarea",
                                            rows: 1,
                                            placeholder: _vm.noteApp
                                              ? ""
                                              : "请输入内容",
                                            disabled: _vm.noteApp,
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.dblEditCustomer()
                                            },
                                          },
                                          model: {
                                            value: _vm.detailForm.appended,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.detailForm,
                                                "appended",
                                                $$v
                                              )
                                            },
                                            expression: "detailForm.appended",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "li",
                                  {
                                    staticStyle: {
                                      width: "100%",
                                      "border-bottom-width": "1px",
                                    },
                                    on: {
                                      mouseenter: function ($event) {
                                        return _vm.changeShowValue("note", true)
                                      },
                                      mouseleave: function ($event) {
                                        return _vm.changeShowValue(
                                          "note",
                                          false
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "textAlign",
                                        staticStyle: {
                                          width: "11%",
                                          height: "40px",
                                        },
                                      },
                                      [_vm._v("备注：")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.detailFormShow.note,
                                            expression: "detailFormShow.note",
                                          },
                                        ],
                                        staticStyle: {
                                          width: "88%",
                                          height: "40px",
                                          "line-height": "40px",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.detailForm.remarks))]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !_vm.detailFormShow.note,
                                            expression: "!detailFormShow.note",
                                          },
                                        ],
                                        staticClass: "input-show",
                                        staticStyle: {
                                          width: "88%",
                                          height: "40px",
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          staticClass: "input-style textarea",
                                          staticStyle: {
                                            width: "100%",
                                            height: "40px",
                                            "line-height": "40px",
                                            border: "none",
                                            "background-color": "#fff",
                                          },
                                          attrs: {
                                            type: "textarea",
                                            rows: 1,
                                            placeholder: "请输入内容",
                                            disabled: "",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.dblEditCustomer()
                                            },
                                          },
                                          model: {
                                            value: _vm.detailForm.remarks,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.detailForm,
                                                "remarks",
                                                $$v
                                              )
                                            },
                                            expression: "detailForm.remarks",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "SelectiveInformation" },
                    [
                      _c("h3", [_vm._v("背景意向")]),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            {
                              attrs: { xs: 24, sm: 24, md: 24, lg: 12, xl: 12 },
                            },
                            [
                              _c("ul", [
                                _c(
                                  "li",
                                  {
                                    on: {
                                      mouseenter: function ($event) {
                                        return _vm.changeShowValue("xuel", true)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "textAlign",
                                        staticStyle: { width: "22%" },
                                      },
                                      [_vm._v("当前学历：")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.detailFormShow.xuel,
                                            expression: "detailFormShow.xuel",
                                          },
                                        ],
                                        staticStyle: { width: "76.6666%" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                            " +
                                            _vm._s(_vm.educationText) +
                                            "\n                                            "
                                        ),
                                        _c("i", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.detailFormShow.xuel,
                                              expression: "detailFormShow.xuel",
                                            },
                                          ],
                                          staticClass:
                                            "iconfont icon-jiantou9 icones",
                                          staticStyle: {
                                            "margin-left": "5px",
                                            color: "#dcdfe6",
                                          },
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !_vm.detailFormShow.xuel,
                                            expression: "!detailFormShow.xuel",
                                          },
                                        ],
                                        staticClass: "input-show",
                                        staticStyle: { width: "76.6666%" },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              placeholder: "请选择",
                                              disabled: "",
                                            },
                                            on: { change: _vm.educationSe },
                                            model: {
                                              value:
                                                _vm.detailForm.educationText,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.detailForm,
                                                  "educationText",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "detailForm.educationText",
                                            },
                                          },
                                          _vm._l(
                                            _vm.educations,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.id,
                                                attrs: {
                                                  label: item.value,
                                                  value: item.id,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "li",
                                  {
                                    on: {
                                      mouseenter: function ($event) {
                                        return _vm.changeShowValue(
                                          "graduate",
                                          true
                                        )
                                      },
                                      mouseleave: function ($event) {
                                        return _vm.changeShowValue(
                                          "graduate",
                                          false
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "textAlign",
                                        staticStyle: { width: "22%" },
                                      },
                                      [_vm._v("毕业院校：")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.detailFormShow.graduate,
                                            expression:
                                              "detailFormShow.graduate",
                                          },
                                        ],
                                        staticStyle: { width: "76.6666%" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                            " +
                                            _vm._s(
                                              _vm.detailForm.GraduatedFrom
                                            ) +
                                            "\n                                        "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "input-show",
                                        staticStyle: { width: "76.6666%" },
                                      },
                                      [
                                        _c("el-input", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                !_vm.detailFormShow.graduate,
                                              expression:
                                                "!detailFormShow.graduate",
                                            },
                                          ],
                                          attrs: {
                                            placeholder: "请输入内容",
                                            disabled: "",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.dblEditCustomer()
                                            },
                                          },
                                          model: {
                                            value: _vm.detailForm.GraduatedFrom,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.detailForm,
                                                "GraduatedFrom",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "detailForm.GraduatedFrom",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "li",
                                  {
                                    on: {
                                      mouseenter: function ($event) {
                                        return _vm.changeShowValue(
                                          "ittngj",
                                          true
                                        )
                                      },
                                      mouseleave: function ($event) {
                                        return _vm.changeShowValue(
                                          "ittngj",
                                          false
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "textAlign",
                                        staticStyle: { width: "22%" },
                                      },
                                      [_vm._v("意向地区：")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.detailFormShow.ittngj,
                                            expression: "detailFormShow.ittngj",
                                          },
                                        ],
                                        staticStyle: { width: "76.6666%" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                            " +
                                            _vm._s(
                                              _vm.detailForm.areaOfInterest
                                            ) +
                                            "\n                                        "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "input-show",
                                        staticStyle: { width: "76.6666%" },
                                      },
                                      [
                                        _c("el-input", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: !_vm.detailFormShow.ittngj,
                                              expression:
                                                "!detailFormShow.ittngj",
                                            },
                                          ],
                                          attrs: {
                                            placeholder: "请输入内容",
                                            disabled: "",
                                          },
                                          on: {
                                            blur: function ($event) {
                                              return _vm.dblEditCustomer()
                                            },
                                            change: function ($event) {
                                              return _vm.dblEditCustomer(
                                                "ittngj"
                                              )
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm.detailForm.areaOfInterest,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.detailForm,
                                                "areaOfInterest",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "detailForm.areaOfInterest",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "li",
                                  {
                                    staticStyle: {
                                      "border-bottom-width": "1px",
                                    },
                                    on: {
                                      mouseenter: function ($event) {
                                        return _vm.changeShowValue(
                                          "ittnxl",
                                          true
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "textAlign",
                                        staticStyle: { width: "22%" },
                                      },
                                      [_vm._v("报考项目：")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.detailFormShow.ittnxl,
                                            expression: "detailFormShow.ittnxl",
                                          },
                                        ],
                                        staticStyle: { width: "76.6666%" },
                                      },
                                      [
                                        _c(
                                          "el-tooltip",
                                          {
                                            staticClass: "item",
                                            staticStyle: { width: "76.6666%" },
                                            attrs: {
                                              "popper-class": "white-space",
                                              effect: "dark",
                                              content: _vm.baokaixiangmu,
                                              placement: "top-start",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "select" },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(_vm.baokaixiangmu)
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("i", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.detailFormShow.ittnxl,
                                              expression:
                                                "detailFormShow.ittnxl",
                                            },
                                          ],
                                          staticClass:
                                            "iconfont icon-jiantou9 icones",
                                          staticStyle: {
                                            "margin-left": "5px",
                                            color: "#dcdfe6",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !_vm.detailFormShow.ittnxl,
                                            expression:
                                              "!detailFormShow.ittnxl",
                                          },
                                        ],
                                        staticClass: "input-show",
                                        staticStyle: { width: "77%" },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              placeholder: "请选择",
                                              disabled: "",
                                            },
                                            on: { change: _vm.projectSe },
                                            model: {
                                              value:
                                                _vm.detailForm.projectTextId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.detailForm,
                                                  "projectTextId",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "detailForm.projectTextId",
                                            },
                                          },
                                          _vm._l(_vm.projects, function (item) {
                                            return _c("el-option", {
                                              key: item.id,
                                              attrs: {
                                                label: item.value,
                                                value: item.id,
                                              },
                                            })
                                          }),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "li",
                                  {
                                    on: {
                                      mouseenter: function ($event) {
                                        return _vm.changeShowValue(
                                          "ittnyx",
                                          true
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "textAlign",
                                        staticStyle: { width: "22%" },
                                      },
                                      [_vm._v("意向院校：")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.detailFormShow.ittnyx,
                                            expression: "detailFormShow.ittnyx",
                                          },
                                        ],
                                        staticStyle: { width: "76.6666%" },
                                      },
                                      [
                                        _c(
                                          "el-tooltip",
                                          {
                                            staticClass: "item",
                                            attrs: {
                                              "popper-class": "white-space",
                                              effect: "dark",
                                              content: _vm.ins,
                                              placement: "top-start",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "select" },
                                              [
                                                _c("span", [
                                                  _vm._v(_vm._s(_vm.ins)),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("i", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.detailFormShow.ittnyx,
                                              expression:
                                                "detailFormShow.ittnyx",
                                            },
                                          ],
                                          staticClass:
                                            "iconfont icon-jiantou9 icones",
                                          staticStyle: {
                                            "margin-left": "5px",
                                            color: "#dcdfe6",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !_vm.detailFormShow.ittnyx,
                                            expression:
                                              "!detailFormShow.ittnyx",
                                          },
                                        ],
                                        staticClass: "input-show",
                                        staticStyle: { width: "76.6666%" },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticClass:
                                              "colleges introduction",
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              "value-key": "id",
                                              multiple: "",
                                              filterable: "",
                                              remote: "",
                                              "reserve-keyword": "",
                                              placeholder: "请输入关键词",
                                              loading: _vm.loading,
                                              disabled: "",
                                            },
                                            on: {
                                              "visible-change": _vm.collegesEs,
                                            },
                                            model: {
                                              value: _vm.detailForm.institu,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.detailForm,
                                                  "institu",
                                                  $$v
                                                )
                                              },
                                              expression: "detailForm.institu",
                                            },
                                          },
                                          _vm._l(
                                            _vm.chatmodes,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.id,
                                                attrs: {
                                                  label: item.title,
                                                  value: item.id,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              attrs: { xs: 24, sm: 24, md: 24, lg: 12, xl: 12 },
                            },
                            [
                              _c("ul", [
                                _c(
                                  "li",
                                  {
                                    on: {
                                      mouseenter: function ($event) {
                                        return _vm.changeShowValue(
                                          "graduatezy",
                                          true
                                        )
                                      },
                                      mouseleave: function ($event) {
                                        return _vm.changeShowValue(
                                          "graduatezy",
                                          false
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("span", { staticClass: "textAlign" }, [
                                      _vm._v("毕业专业："),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.detailFormShow.graduatezy,
                                            expression:
                                              "detailFormShow.graduatezy",
                                          },
                                        ],
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.detailForm.GraduationMajor)
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "input-show" },
                                      [
                                        _c("el-input", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                !_vm.detailFormShow.graduatezy,
                                              expression:
                                                "!detailFormShow.graduatezy",
                                            },
                                          ],
                                          attrs: {
                                            placeholder: "请输入内容",
                                            disabled: "",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.dblEditCustomer()
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm.detailForm.GraduationMajor,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.detailForm,
                                                "GraduationMajor",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "detailForm.GraduationMajor",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "li",
                                  {
                                    on: {
                                      mouseenter: function ($event) {
                                        return _vm.changeShowValue(
                                          "ittnzy",
                                          true
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("span", { staticClass: "textAlign" }, [
                                      _vm._v("意向专业："),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.detailFormShow.ittnzy,
                                            expression: "detailFormShow.ittnzy",
                                          },
                                        ],
                                      },
                                      [
                                        _c(
                                          "el-tooltip",
                                          {
                                            staticClass: "item",
                                            attrs: {
                                              "popper-class": "white-space",
                                              effect: "dark",
                                              content: _vm.Intended,
                                              placement: "top",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "select" },
                                              [
                                                _c("span", [
                                                  _vm._v(_vm._s(_vm.Intended)),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("i", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.detailFormShow.ittnzy,
                                              expression:
                                                "detailFormShow.ittnzy",
                                            },
                                          ],
                                          staticClass:
                                            "iconfont icon-jiantou9 icones",
                                          staticStyle: {
                                            "margin-left": "5px",
                                            color: "#dcdfe6",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !_vm.detailFormShow.ittnzy,
                                            expression:
                                              "!detailFormShow.ittnzy",
                                          },
                                        ],
                                        staticClass: "input-show",
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticClass: "colleges",
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              "value-key": "id",
                                              multiple: "",
                                              filterable: "",
                                              remote: "",
                                              "reserve-keyword": "",
                                              placeholder: "请搜索意向专业",
                                              loading: _vm.loading,
                                              disabled: "",
                                            },
                                            on: {
                                              "visible-change": _vm.showEs,
                                            },
                                            model: {
                                              value:
                                                _vm.detailForm.IntendedMajor,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.detailForm,
                                                  "IntendedMajor",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "detailForm.IntendedMajor",
                                            },
                                          },
                                          _vm._l(
                                            _vm.Intendeds,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.id,
                                                attrs: {
                                                  label: item.title,
                                                  value: item.id,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "li",
                                  {
                                    staticStyle: {
                                      "border-bottom-width": "1px",
                                    },
                                    on: {
                                      mouseenter: function ($event) {
                                        return _vm.changeShowValue(
                                          "ittnxm",
                                          true
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("span", { staticClass: "textAlign" }, [
                                      _vm._v("意向简章："),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.detailFormShow.ittnxm,
                                            expression: "detailFormShow.ittnxm",
                                          },
                                        ],
                                      },
                                      [
                                        _c(
                                          "el-tooltip",
                                          {
                                            staticClass: "item",
                                            attrs: {
                                              "popper-class": "white-space",
                                              effect: "dark",
                                              content: _vm.General,
                                              placement: "top-start",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "select" },
                                              [
                                                _c("span", [
                                                  _vm._v(_vm._s(_vm.General)),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("i", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.detailFormShow.ittnxm,
                                              expression:
                                                "detailFormShow.ittnxm",
                                            },
                                          ],
                                          staticClass:
                                            "iconfont icon-jiantou9 icones",
                                          staticStyle: {
                                            "margin-left": "5px",
                                            color: "#dcdfe6",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !_vm.detailFormShow.ittnxm,
                                            expression:
                                              "!detailFormShow.ittnxm",
                                          },
                                        ],
                                        staticClass: "input-show",
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticClass:
                                              "colleges introduction",
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              "value-key": "id",
                                              multiple: "",
                                              filterable: "",
                                              remote: "",
                                              "reserve-keyword": "",
                                              placeholder: "请搜索意向简章",
                                              loading: _vm.loading,
                                              disabled: "",
                                            },
                                            on: {
                                              "visible-change": _vm.GeneralEs,
                                            },
                                            model: {
                                              value:
                                                _vm.detailForm.GeneralRules,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.detailForm,
                                                  "GeneralRules",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "detailForm.GeneralRules",
                                            },
                                          },
                                          _vm._l(
                                            _vm.GenalRules,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.id,
                                                attrs: {
                                                  label: item.title,
                                                  value: item.id,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "li",
                                  {
                                    staticStyle: {
                                      "border-bottom-width": "1px",
                                    },
                                    on: {
                                      mouseenter: function ($event) {
                                        return _vm.changeShowValue(
                                          "budget",
                                          true
                                        )
                                      },
                                      mouseleave: function ($event) {
                                        return _vm.changeShowValue(
                                          "budget",
                                          false
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("span", { staticClass: "textAlign" }, [
                                      _vm._v("预算："),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.detailFormShow.budget,
                                            expression: "detailFormShow.budget",
                                          },
                                        ],
                                      },
                                      [_vm._v(_vm._s(_vm.detailForm.budget))]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "input-show" },
                                      [
                                        _c("el-input", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: !_vm.detailFormShow.budget,
                                              expression:
                                                "!detailFormShow.budget",
                                            },
                                          ],
                                          attrs: {
                                            placeholder: "请输入内容",
                                            disabled: "",
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.dblEditCustomer()
                                            },
                                          },
                                          model: {
                                            value: _vm.detailForm.budget,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.detailForm,
                                                "budget",
                                                $$v
                                              )
                                            },
                                            expression: "detailForm.budget",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-collapse",
                        {
                          model: {
                            value: _vm.activeNames,
                            callback: function ($$v) {
                              _vm.activeNames = $$v
                            },
                            expression: "activeNames",
                          },
                        },
                        [
                          _c(
                            "el-collapse-item",
                            { attrs: { name: "1" } },
                            [
                              _c("template", { slot: "title" }, [
                                _c("h3", { staticClass: "title" }, [
                                  _vm._v("其他信息"),
                                ]),
                                _vm._v(" "),
                                _c("h5", [_vm._v("   (点击展开/收缩)")]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    {
                                      attrs: {
                                        xs: 24,
                                        sm: 24,
                                        md: 24,
                                        lg: 12,
                                        xl: 12,
                                      },
                                    },
                                    [
                                      _c("ul", [
                                        _c("li", [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "textAlign",
                                              staticStyle: { width: "22%" },
                                            },
                                            [_vm._v("性别：")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "input-show",
                                              staticStyle: {
                                                width: "76.66666%",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-radio-group",
                                                {
                                                  attrs: {
                                                    size: "mini",
                                                    disabled: "",
                                                  },
                                                  on: {
                                                    change: _vm.detailFormSex,
                                                  },
                                                  model: {
                                                    value: _vm.detailForm.sex,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.detailForm,
                                                        "sex",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "detailForm.sex",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-radio",
                                                    { attrs: { label: "0" } },
                                                    [_vm._v("男")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "el-radio",
                                                    { attrs: { label: "1" } },
                                                    [_vm._v("女")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "li",
                                          {
                                            on: {
                                              mouseenter: function ($event) {
                                                return _vm.changeShowValue(
                                                  "timeline",
                                                  true
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "textAlign",
                                                staticStyle: { width: "22%" },
                                              },
                                              [_vm._v("预计入学：")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _vm.detailFormShow
                                                        .timeline,
                                                    expression:
                                                      "detailFormShow.timeline",
                                                  },
                                                ],
                                                staticStyle: {
                                                  width: "76.6666%",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                    " +
                                                    _vm._s(_vm.entranceTextEs) +
                                                    "\n                                                    "
                                                ),
                                                _c("i", {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        _vm.detailFormShow
                                                          .timeline,
                                                      expression:
                                                        "detailFormShow.timeline",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "iconfont icon-jiantou9 icones",
                                                  staticStyle: {
                                                    "margin-left": "5px",
                                                    color: "#dcdfe6",
                                                  },
                                                }),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      !_vm.detailFormShow
                                                        .timeline,
                                                    expression:
                                                      "!detailFormShow.timeline",
                                                  },
                                                ],
                                                staticClass: "input-show",
                                                staticStyle: {
                                                  width: "76.6666%",
                                                },
                                              },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    staticStyle: {
                                                      width: "100%",
                                                    },
                                                    attrs: {
                                                      filterable: "",
                                                      placeholder:
                                                        "请选择入学时间",
                                                      disabled: "",
                                                    },
                                                    on: {
                                                      change:
                                                        _vm.detailFormEntrance,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.detailForm
                                                          .entranceTexts,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.detailForm,
                                                          "entranceTexts",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "detailForm.entranceTexts",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.entrance,
                                                    function (item) {
                                                      return _c("el-option", {
                                                        key: item.id,
                                                        attrs: {
                                                          label: item.value,
                                                          value: item.id,
                                                        },
                                                      })
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "li",
                                          {
                                            on: {
                                              mouseenter: function ($event) {
                                                return _vm.changeShowValue(
                                                  "station",
                                                  true
                                                )
                                              },
                                              mouseleave: function ($event) {
                                                return _vm.changeShowValue(
                                                  "station",
                                                  false
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "textAlign",
                                                staticStyle: { width: "22%" },
                                              },
                                              [_vm._v("岗位：")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _vm.detailFormShow
                                                        .station,
                                                    expression:
                                                      "detailFormShow.station",
                                                  },
                                                ],
                                                staticStyle: {
                                                  width: "76.6666%",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                    " +
                                                    _vm._s(
                                                      _vm.detailForm.post
                                                    ) +
                                                    "\n                                                "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      !_vm.detailFormShow
                                                        .station,
                                                    expression:
                                                      "!detailFormShow.station",
                                                  },
                                                ],
                                                staticClass: "input-show",
                                                staticStyle: {
                                                  width: "76.6666%",
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    placeholder: "请输入内容",
                                                    disabled: "",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.dblEditCustomer()
                                                    },
                                                  },
                                                  model: {
                                                    value: _vm.detailForm.post,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.detailForm,
                                                        "post",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "detailForm.post",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "li",
                                          {
                                            on: {
                                              mouseenter: function ($event) {
                                                return _vm.changeShowValue(
                                                  "xueshuchengji",
                                                  true
                                                )
                                              },
                                              mouseleave: function ($event) {
                                                return _vm.changeShowValue(
                                                  "xueshuchengji",
                                                  false
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "textAlign",
                                                staticStyle: { width: "22%" },
                                              },
                                              [_vm._v("学术成绩：")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _vm.detailFormShow
                                                        .xueshuchengji,
                                                    expression:
                                                      "detailFormShow.xueshuchengji",
                                                  },
                                                ],
                                                staticStyle: {
                                                  width: "76.6666%",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                    " +
                                                    _vm._s(
                                                      _vm.detailForm
                                                        .achievements
                                                    ) +
                                                    "\n                                                "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "input-show",
                                                staticStyle: {
                                                  width: "76.6666%",
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        !_vm.detailFormShow
                                                          .xueshuchengji,
                                                      expression:
                                                        "!detailFormShow.xueshuchengji",
                                                    },
                                                  ],
                                                  attrs: {
                                                    placeholder: "请输入内容",
                                                    disabled: "",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.dblEditCustomer()
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.detailForm
                                                        .achievements,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.detailForm,
                                                        "achievements",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "detailForm.achievements",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    {
                                      attrs: {
                                        xs: 24,
                                        sm: 24,
                                        md: 24,
                                        lg: 12,
                                        xl: 12,
                                      },
                                    },
                                    [
                                      _c("ul", [
                                        _c(
                                          "li",
                                          {
                                            on: {
                                              mouseenter: function ($event) {
                                                return _vm.changeShowValue(
                                                  "age",
                                                  true
                                                )
                                              },
                                              mouseleave: function ($event) {
                                                return _vm.changeShowValue(
                                                  "age",
                                                  false
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "textAlign" },
                                              [_vm._v("年龄：")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _vm.detailFormShow.age,
                                                    expression:
                                                      "detailFormShow.age",
                                                  },
                                                ],
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.detailForm.Age)
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      !_vm.detailFormShow.age,
                                                    expression:
                                                      "!detailFormShow.age",
                                                  },
                                                ],
                                                staticClass: "input-show",
                                              },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    oninput:
                                                      (_vm.detailForm.Age =
                                                        _vm.detailForm.Age.replace(
                                                          /[^\d]/g,
                                                          ""
                                                        )),
                                                    maxlength: "3",
                                                    placeholder: "请输入内容",
                                                    disabled: "",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.dblEditCustomer()
                                                    },
                                                  },
                                                  model: {
                                                    value: _vm.detailForm.Age,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.detailForm,
                                                        "Age",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "detailForm.Age",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "li",
                                          {
                                            on: {
                                              mouseenter: function ($event) {
                                                return _vm.changeShowValue(
                                                  "telephone",
                                                  true
                                                )
                                              },
                                              mouseleave: function ($event) {
                                                return _vm.changeShowValue(
                                                  "telephone",
                                                  false
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "textAlign" },
                                              [_vm._v("座机：")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _vm.detailFormShow
                                                        .telephone,
                                                    expression:
                                                      "detailFormShow.telephone",
                                                  },
                                                ],
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.detailForm.Landline
                                                  )
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "input-show" },
                                              [
                                                _c("el-input", {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        !_vm.detailFormShow
                                                          .telephone,
                                                      expression:
                                                        "!detailFormShow.telephone",
                                                    },
                                                  ],
                                                  attrs: {
                                                    placeholder: "请输入内容",
                                                    disabled: "",
                                                  },
                                                  on: {
                                                    blur: function ($event) {
                                                      return _vm.dblEditCustomer()
                                                    },
                                                    change: function ($event) {
                                                      return _vm.Landlines(
                                                        _vm.detailForm.Landline
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.detailForm.Landline,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.detailForm,
                                                        "Landline",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "detailForm.Landline",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "li",
                                          {
                                            on: {
                                              mouseenter: function ($event) {
                                                return _vm.changeShowValue(
                                                  "occupation",
                                                  true
                                                )
                                              },
                                              mouseleave: function ($event) {
                                                return _vm.changeShowValue(
                                                  "occupation",
                                                  false
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "textAlign" },
                                              [_vm._v("职业：")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _vm.detailFormShow
                                                        .occupation,
                                                    expression:
                                                      "detailFormShow.occupation",
                                                  },
                                                ],
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.detailForm.occupation
                                                  )
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      !_vm.detailFormShow
                                                        .occupation,
                                                    expression:
                                                      "!detailFormShow.occupation",
                                                  },
                                                ],
                                                staticClass: "input-show",
                                              },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    placeholder: "请输入内容",
                                                    disabled: "",
                                                  },
                                                  on: {
                                                    blur: function ($event) {
                                                      return _vm.dblEditCustomer()
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.detailForm.occupation,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.detailForm,
                                                        "occupation",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "detailForm.occupation",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "li",
                                          {
                                            on: {
                                              mouseenter: function ($event) {
                                                return _vm.changeShowValue(
                                                  "yuyanchengji",
                                                  true
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "textAlign" },
                                              [_vm._v("语言成绩：")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _vm.detailFormShow
                                                        .yuyanchengji,
                                                    expression:
                                                      "detailFormShow.yuyanchengji",
                                                  },
                                                ],
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.detailForm.language
                                                  )
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              [
                                                _c("el-input", {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        !_vm.detailFormShow
                                                          .yuyanchengji,
                                                      expression:
                                                        "!detailFormShow.yuyanchengji",
                                                    },
                                                  ],
                                                  attrs: {
                                                    placeholder: "请输入内容",
                                                    disabled: "",
                                                  },
                                                  on: {
                                                    blur: function ($event) {
                                                      return _vm.dblEditCustomer()
                                                    },
                                                    change: function ($event) {
                                                      return _vm.dblEditCustomer(
                                                        "yuyanchengji"
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.detailForm.language,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.detailForm,
                                                        "language",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "detailForm.language",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    {
                                      attrs: {
                                        xs: 24,
                                        sm: 24,
                                        md: 24,
                                        lg: 12,
                                        xl: 12,
                                      },
                                    },
                                    [
                                      _c("ul", [
                                        _c(
                                          "li",
                                          {
                                            staticStyle: {
                                              "border-bottom-width": "1px",
                                            },
                                            on: {
                                              mouseenter: function ($event) {
                                                return _vm.changeShowValue(
                                                  "industry",
                                                  true
                                                )
                                              },
                                              mouseleave: function ($event) {
                                                return _vm.changeShowValue(
                                                  "industry",
                                                  false
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "textAlign",
                                                staticStyle: { width: "22%" },
                                              },
                                              [_vm._v("行业：")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _vm.detailFormShow
                                                        .industry,
                                                    expression:
                                                      "detailFormShow.industry",
                                                  },
                                                ],
                                                staticStyle: {
                                                  width: "76.6666%",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                    " +
                                                    _vm._s(
                                                      _vm.detailForm.industry
                                                    ) +
                                                    "\n                                                "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      !_vm.detailFormShow
                                                        .industry,
                                                    expression:
                                                      "!detailFormShow.industry",
                                                  },
                                                ],
                                                staticClass: "input-show",
                                                staticStyle: {
                                                  width: "76.6666%",
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    placeholder: "请输入内容",
                                                    disabled: "",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.dblEditCustomer()
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.detailForm.industry,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.detailForm,
                                                        "industry",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "detailForm.industry",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "li",
                                          {
                                            staticStyle: {
                                              "border-bottom-width": "1px",
                                            },
                                            on: {
                                              mouseenter: function ($event) {
                                                return _vm.changeShowValue(
                                                  "hzID",
                                                  true
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "textAlign",
                                                staticStyle: { width: "22%" },
                                              },
                                              [_vm._v("护照号码：")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _vm.detailFormShow.hzID,
                                                    expression:
                                                      "detailFormShow.hzID",
                                                  },
                                                ],
                                                staticStyle: {
                                                  width: "76.6666%",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                    " +
                                                    _vm._s(
                                                      _vm.detailForm.Passport
                                                    ) +
                                                    "\n                                                "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      !_vm.detailFormShow.hzID,
                                                    expression:
                                                      "!detailFormShow.hzID",
                                                  },
                                                ],
                                                staticClass: "input-show",
                                                staticStyle: {
                                                  width: "76.6666%",
                                                },
                                              },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    placeholder: "请输入内容",
                                                    disabled: "",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.dblEditCustomer()
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.detailForm.Passport,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.detailForm,
                                                        "Passport",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "detailForm.Passport",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    {
                                      attrs: {
                                        xs: 24,
                                        sm: 24,
                                        md: 24,
                                        lg: 12,
                                        xl: 12,
                                      },
                                    },
                                    [
                                      _c("ul", [
                                        _c(
                                          "li",
                                          {
                                            staticStyle: {
                                              "border-bottom-width": "1px",
                                            },
                                            on: {
                                              mouseenter: function ($event) {
                                                return _vm.changeShowValue(
                                                  "hobby",
                                                  true
                                                )
                                              },
                                              mouseleave: function ($event) {
                                                return _vm.changeShowValue(
                                                  "hobby",
                                                  false
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "textAlign" },
                                              [_vm._v("性格：")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _vm.detailFormShow.hobby,
                                                    expression:
                                                      "detailFormShow.hobby",
                                                  },
                                                ],
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.detailForm.hobby)
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      !_vm.detailFormShow.hobby,
                                                    expression:
                                                      "!detailFormShow.hobby",
                                                  },
                                                ],
                                                staticClass: "input-show",
                                              },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    placeholder: "请输入内容",
                                                    disabled: "",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.dblEditCustomer()
                                                    },
                                                  },
                                                  model: {
                                                    value: _vm.detailForm.hobby,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.detailForm,
                                                        "hobby",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "detailForm.hobby",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    {
                                      attrs: {
                                        xs: 24,
                                        sm: 24,
                                        md: 24,
                                        lg: 24,
                                        xl: 24,
                                      },
                                    },
                                    [
                                      _c("ul", [
                                        _c(
                                          "li",
                                          {
                                            staticStyle: {
                                              "border-bottom-width": "1px",
                                            },
                                            on: {
                                              mouseenter: function ($event) {
                                                return _vm.changeShowValue(
                                                  "adress",
                                                  true
                                                )
                                              },
                                              mouseleave: function ($event) {
                                                return _vm.changeShowValue(
                                                  "adress",
                                                  false
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "textAlign",
                                                staticStyle: { width: "11%" },
                                              },
                                              [_vm._v("详细住址：")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _vm.detailFormShow.adress,
                                                    expression:
                                                      "detailFormShow.adress",
                                                  },
                                                ],
                                                staticStyle: { width: "88%" },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                    " +
                                                    _vm._s(
                                                      _vm.detailForm.address
                                                    ) +
                                                    "\n                                                "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      !_vm.detailFormShow
                                                        .adress,
                                                    expression:
                                                      "!detailFormShow.adress",
                                                  },
                                                ],
                                                staticClass: "input-show",
                                                staticStyle: { width: "78%" },
                                              },
                                              [
                                                _c("el-input", {
                                                  attrs: {
                                                    placeholder: "请输入内容",
                                                    disabled: "",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.dblEditCustomer()
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.detailForm.address,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.detailForm,
                                                        "address",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "detailForm.address",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-row",
                                { staticStyle: { "margin-bottom": "10px" } },
                                [
                                  _c(
                                    "el-col",
                                    {
                                      attrs: {
                                        xs: 24,
                                        sm: 24,
                                        md: 24,
                                        lg: 24,
                                        xl: 24,
                                      },
                                    },
                                    [
                                      _c("ul", [
                                        _c(
                                          "li",
                                          {
                                            staticStyle: {
                                              width: "100%",
                                              "border-bottom-width": "1px",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "textAlign",
                                                staticStyle: { width: "11%" },
                                              },
                                              [_vm._v("客户标签：")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "label-peer",
                                                staticStyle: { width: "88%" },
                                              },
                                              [
                                                _c(
                                                  "el-checkbox-group",
                                                  {
                                                    attrs: { disabled: "" },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.dblEditCustomer()
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.detailForm
                                                          .labelpeer,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.detailForm,
                                                          "labelpeer",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "detailForm.labelpeer",
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.customer,
                                                    function (item) {
                                                      return _c(
                                                        "el-checkbox",
                                                        {
                                                          key: item.id,
                                                          attrs: {
                                                            label: item.id,
                                                          },
                                                        },
                                                        [
                                                          _c("div", [
                                                            _vm._v(
                                                              _vm._s(item.value)
                                                            ),
                                                          ]),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "fl tabRight" },
                  [
                    _c(
                      "div",
                      { staticStyle: { "padding-top": "15px" } },
                      [
                        _c(
                          "el-form",
                          {
                            attrs: {
                              "label-position": "labelPosition",
                              "label-width": "30%",
                            },
                          },
                          [
                            _c(
                              "el-form-item",
                              {
                                staticStyle: { "margin-bottom": "10px" },
                                attrs: { label: "学员级别:" },
                              },
                              [
                                _c("el-cascader", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    options: _vm.StudentLevel,
                                    disabled: "",
                                  },
                                  on: { change: _vm.handleChange },
                                  model: {
                                    value: _vm.detailForm.Student,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.detailForm, "Student", $$v)
                                    },
                                    expression: "detailForm.Student",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "NewRecords",
                        staticStyle: {
                          overflow: "hidden",
                          "margin-bottom": "10px",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "NewRecordsBtn",
                            staticStyle: { width: "70%" },
                            attrs: { type: "primary", round: "", disabled: "" },
                            on: {
                              click: function ($event) {
                                _vm.dialogFormVisible = true
                              },
                            },
                          },
                          [_vm._v("新建跟进记录")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.dialogFormVisible
                      ? _c(
                          "el-row",
                          { staticStyle: { "padding-bottom": "16px" } },
                          [
                            _c("el-col", { attrs: { span: 24 } }, [
                              _c(
                                "div",
                                {
                                  staticClass: "grid-content bg-purple",
                                  staticStyle: {
                                    "border-bottom": "1px solid #e8e8e8",
                                    padding: "20px 12px 12px 12px",
                                  },
                                },
                                [
                                  _c(
                                    "el-form",
                                    {
                                      attrs: {
                                        "label-position": "labelPosition",
                                        "label-width": "30%",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "主题:" } },
                                        [
                                          _c("el-input", {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              type: "textarea",
                                              rows: 3,
                                            },
                                            model: {
                                              value: _vm.form.theme,
                                              callback: function ($$v) {
                                                _vm.$set(_vm.form, "theme", $$v)
                                              },
                                              expression: "form.theme",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "类型:" } },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                placeholder: "请选择类型",
                                              },
                                              model: {
                                                value: _vm.form.typese,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "typese",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.typese",
                                              },
                                            },
                                            [
                                              _c("el-option", {
                                                attrs: {
                                                  label: "电话",
                                                  value: "1",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("el-option", {
                                                attrs: {
                                                  label: "QQ",
                                                  value: "2",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("el-option", {
                                                attrs: {
                                                  label: "微信",
                                                  value: "3",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("el-option", {
                                                attrs: {
                                                  label: "面聊",
                                                  value: "4",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "下次回访日期:" } },
                                        [
                                          _c("el-date-picker", {
                                            staticClass: "Picker",
                                            staticStyle: {
                                              border: "none",
                                              width: "100%",
                                            },
                                            attrs: {
                                              type: "datetime",
                                              "default-value": new Date(),
                                              "value-format": "timestamp",
                                              placeholder: "选择日期时间",
                                              "picker-options":
                                                _vm.pickerOptions,
                                            },
                                            on: { change: _vm.getUnfollowmsg },
                                            model: {
                                              value: _vm.form.next_follow,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "next_follow",
                                                  $$v
                                                )
                                              },
                                              expression: "form.next_follow",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: _vm.Unfollowshow,
                                                  expression: "Unfollowshow",
                                                },
                                              ],
                                              staticStyle: { color: "red" },
                                            },
                                            [
                                              _vm._v(
                                                "当天已有" +
                                                  _vm._s(_vm.Unfollow) +
                                                  "条待回访数据"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-form-item",
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  _vm.dialogFormVisible = false
                                                },
                                              },
                                            },
                                            [_vm._v("取消")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-button",
                                            {
                                              attrs: { type: "primary" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.NewFollowupRecordSure()
                                                },
                                              },
                                            },
                                            [_vm._v("提交")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "placemen" },
                      [
                        _c(
                          "el-timeline",
                          _vm._l(_vm.activities, function (activity, index) {
                            return _c(
                              "el-timeline-item",
                              {
                                key: index,
                                attrs: {
                                  placement: "top",
                                  timestamp: activity.create_time,
                                },
                              },
                              [
                                _c("span", { staticClass: "eD8CF0 mr10" }, [
                                  _vm._v(_vm._s(activity.type)),
                                ]),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(_vm._s(activity.create_realnaem)),
                                ]),
                                _vm._v(" "),
                                _c("br"),
                                _vm._v(" "),
                                _c("br"),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v(
                                    "下次回访日期：" +
                                      _vm._s(activity.next_time)
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("p", [_vm._v(_vm._s(activity.content))]),
                              ]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-tab-pane", { attrs: { label: "订单信息" } }, [
                _c(
                  "div",
                  { staticClass: "fjsb", staticStyle: { width: "100%" } },
                  [
                    _c(
                      "ul",
                      {
                        staticStyle: {
                          width: "100%",
                          "border-width": "1px",
                          display: "inline-block",
                        },
                      },
                      [
                        _c("li", { staticClass: "tabLists" }, [
                          _c(
                            "table",
                            {
                              staticClass: "tabstyles",
                              attrs: { border: "1", rules: "all" },
                            },
                            [
                              _c("tr", [
                                _c("th", [_vm._v("客户名称：")]),
                                _vm._v(" "),
                                _c("td", [_vm._v(_vm._s(_vm.info.cname))]),
                                _vm._v(" "),
                                _c("th", [_vm._v("客户创建人:")]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(_vm._s(_vm.info.create_realname)),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("th", [_vm._v("成交顾问：")]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(_vm._s(_vm.info.follow_realname)),
                                ]),
                                _vm._v(" "),
                                _c("th", [_vm._v("数据所属部门:")]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(_vm._s(_vm.info.structure_name)),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("th", [_vm._v("学员来源:")]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(_vm._s(_vm.info.source_name)),
                                ]),
                                _vm._v(" "),
                                _c("th", [_vm._v("客户种类:")]),
                                _vm._v(" "),
                                _vm.info.leibie == 1
                                  ? _c("td", [
                                      _vm._v(
                                        "\n                                        广告\n                                    "
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.info.leibie == 2
                                  ? _c("td", [
                                      _vm._v(
                                        "\n                                        终端\n                                    "
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.info.leibie == 3
                                  ? _c("td", [
                                      _vm._v(
                                        "\n                                        渠道\n                                    "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("th", [_vm._v("缴费日期:")]),
                                _vm._v(" "),
                                _c("td", [_vm._v(_vm._s(_vm.info.pay_time))]),
                                _vm._v(" "),
                                _c("th", [_vm._v("汇款人名称:")]),
                                _vm._v(" "),
                                _c("td", [_vm._v(_vm._s(_vm.info.remitter))]),
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("th", [_vm._v("支付方式:")]),
                                _vm._v(" "),
                                _vm.info.pay_type == 0
                                  ? _c("td", [
                                      _vm._v(
                                        "\n                                        无\n                                    "
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.info.pay_type == 1
                                  ? _c("td", [
                                      _vm._v(
                                        "\n                                        微信\n                                    "
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.info.pay_type == 2
                                  ? _c("td", [
                                      _vm._v(
                                        "\n                                        支付宝\n                                    "
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.info.pay_type == 3
                                  ? _c("td", [
                                      _vm._v(
                                        "\n                                        刷卡\n                                    "
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.info.pay_type == 4
                                  ? _c("td", [
                                      _vm._v(
                                        "\n                                        现金\n                                    "
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("th", [_vm._v("缴费用途:")]),
                                _vm._v(" "),
                                _vm.info.pay_purpose == 0
                                  ? _c("td", [
                                      _vm._v(
                                        "\n                                        无\n                                    "
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.info.pay_purpose == 1
                                  ? _c("td", [
                                      _vm._v(
                                        "\n                                        报名费\n                                    "
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.info.pay_purpose == 2
                                  ? _c("td", [
                                      _vm._v(
                                        "\n                                        定金\n                                    "
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.info.pay_purpose == 3
                                  ? _c("td", [
                                      _vm._v(
                                        "\n                                        学费\n                                    "
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.info.pay_purpose == 4
                                  ? _c("td", [
                                      _vm._v(
                                        "\n                                        资料费\n                                    "
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.info.pay_purpose == 5
                                  ? _c("td", [
                                      _vm._v(
                                        "\n                                        语言培训费\n                                    "
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.info.pay_purpose == 6
                                  ? _c("td", [
                                      _vm._v(
                                        "\n                                        普通留学申请费\n                                    "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                              _vm._v(" "),
                              _vm.info.order_type == 1
                                ? _c("tr", [
                                    _c("th", [_vm._v("收款分期期数:")]),
                                    _vm._v(" "),
                                    _vm.info.periodization == 0
                                      ? _c("td", [
                                          _vm._v(
                                            "\n                                        无\n                                    "
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.info.periodization == 1
                                      ? _c("td", [
                                          _vm._v(
                                            "\n                                        一次性全款\n                                    "
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.info.periodization == 2
                                      ? _c("td", [
                                          _vm._v(
                                            "\n                                        分两次全款\n                                    "
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.info.periodization == 3
                                      ? _c("td", [
                                          _vm._v(
                                            "\n                                        分三次全款\n                                    "
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.info.periodization == 4
                                      ? _c("td", [
                                          _vm._v(
                                            "\n                                        分四次全款\n                                    "
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("th", [_vm._v("收款批次:")]),
                                    _vm._v(" "),
                                    _vm.info.periodization_set == 1
                                      ? _c("td", [
                                          _vm._v(
                                            "\n                                        第一次付款\n                                    "
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.info.periodization_set == 2
                                      ? _c("td", [
                                          _vm._v(
                                            "\n                                        第二次付款\n                                    "
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.info.periodization_set == 3
                                      ? _c("td", [
                                          _vm._v(
                                            "\n                                        第三次付款\n                                    "
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.info.periodization_set == 4
                                      ? _c("td", [
                                          _vm._v(
                                            "\n                                        第四次付款\n                                    "
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.info.periodization_set == 5
                                      ? _c("td", [
                                          _vm._v(
                                            "\n                                        一次性付全款\n                                    "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "tr",
                                [
                                  _vm.info.order_type == 1
                                    ? [
                                        _c("th", [_vm._v("应收款总额（元）:")]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _vm._v(_vm._s(_vm.info.should_money)),
                                        ]),
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.info.order_type == 1
                                    ? [
                                        _c("th", [
                                          _vm._v("本次收款金额（元）:"),
                                        ]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _vm._v(_vm._s(_vm.info.money)),
                                        ]),
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.info.order_type != 1
                                    ? [
                                        _c("th", [_vm._v("收款金额（元）:")]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _vm._v(_vm._s(_vm.info.money)),
                                        ]),
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.info.order_type != 1
                                    ? [
                                        _c("th", [_vm._v("所选专业:")]),
                                        _vm._v(" "),
                                        _vm.info.major_select == 1
                                          ? _c("td", [
                                              _vm._v(
                                                "\n                                        经管\n                                    "
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.info.major_select == 2
                                          ? _c("td", [
                                              _vm._v(
                                                "\n                                        国经\n                                    "
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.info.major_select == 3
                                          ? _c("td", [
                                              _vm._v(
                                                "\n                                        马哲\n                                    "
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.info.major_select == 4
                                          ? _c("td", [
                                              _vm._v(
                                                "\n                                        旅游管理\n                                    "
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.info.major_select == 5
                                          ? _c("td", [
                                              _vm._v(
                                                "\n                                        国际贸易\n                                    "
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.info.major_select == 6
                                          ? _c("td", [
                                              _vm._v(
                                                "\n                                        税务管理\n                                    "
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.info.major_select == 7
                                          ? _c("td", [
                                              _vm._v(
                                                "\n                                        研修\n                                    "
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.info.major_select == 8
                                          ? _c("td", [
                                              _vm._v(
                                                "\n                                        中科院人工智能\n                                    "
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _vm.info.order_type == 1
                                ? _c("tr", [
                                    _c("th", [_vm._v("院校层次:")]),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            attrs: { disabled: "" },
                                            model: {
                                              value: _vm.info.school_levels,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.info,
                                                  "school_levels",
                                                  $$v
                                                )
                                              },
                                              expression: "info.school_levels",
                                            },
                                          },
                                          _vm._l(
                                            _vm.schoolLevels,
                                            function (item, index) {
                                              return _c("el-option", {
                                                key: index,
                                                attrs: {
                                                  label: item.name,
                                                  value: item.id,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("th", [_vm._v("升学层次:")]),
                                    _vm._v(" "),
                                    _vm.info.study_level == 0
                                      ? _c("td", [
                                          _vm._v(
                                            "\n                                        无\n                                    "
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.info.study_level == 1
                                      ? _c("td", [
                                          _vm._v(
                                            "\n                                        专升硕\n                                    "
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.info.study_level == 2
                                      ? _c("td", [
                                          _vm._v(
                                            "\n                                        本升硕\n                                    "
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.info.study_level == 3
                                      ? _c("td", [
                                          _vm._v(
                                            "\n                                        硕升博\n                                    "
                                          ),
                                        ])
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "tr",
                                [
                                  _vm.info.order_type == 1
                                    ? [
                                        _c("th", [_vm._v("学习专业:")]),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                attrs: { disabled: "" },
                                                model: {
                                                  value: _vm.info.study_major,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.info,
                                                      "study_major",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "info.study_major",
                                                },
                                              },
                                              _vm._l(
                                                _vm.study_majorArr,
                                                function (item, index) {
                                                  return _c("el-option", {
                                                    key: index,
                                                    attrs: {
                                                      label: item.name,
                                                      value: item.id,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.info.order_type != 1
                                    ? [
                                        _c("th", [_vm._v("班级年份:")]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(_vm.info.particular_year)
                                          ),
                                        ]),
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("th"),
                                  _vm._v(" "),
                                  _c("td"),
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c("tr", [
                                _c("th", [_vm._v("备注:")]),
                                _vm._v(" "),
                                _c("td", [_vm._v(_vm._s(_vm.info.note))]),
                                _vm._v(" "),
                                _c("th", [_vm._v("支付凭证:")]),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  { staticClass: "poi blueFont" },
                                  _vm._l(_vm.imgPay, function (item, index) {
                                    return _vm.info.picture_list
                                      ? _c("el-image", {
                                          key: index,
                                          staticStyle: {
                                            width: "170px",
                                            height: "120px",
                                            "margin-right": "20px",
                                          },
                                          attrs: {
                                            fit: "contain",
                                            src: item.url,
                                            "preview-src-list": _vm.srcList,
                                          },
                                        })
                                      : _vm._e()
                                  }),
                                  1
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("mailListEs", {
        ref: "staffSelect",
        attrs: {
          radio: _vm.radioEs,
          condition: _vm.condition,
          visible: _vm.mailListVisibleEs,
        },
        on: {
          "update:visible": function ($event) {
            _vm.mailListVisibleEs = $event
          },
          staffSelectConfirmEs: _vm.staffSelectConfirmEs,
        },
        model: {
          value: _vm.staffResultEs,
          callback: function ($$v) {
            _vm.staffResultEs = $$v
          },
          expression: "staffResultEs",
        },
      }),
      _vm._v(" "),
      _c("mailListUp", {
        ref: "staffSelect",
        attrs: {
          getType: _vm.get_type,
          radio: _vm.radioUp,
          condition: _vm.condition,
          visible: _vm.mailListVisibleUp,
        },
        on: {
          "update:visible": function ($event) {
            _vm.mailListVisibleUp = $event
          },
          SharedSustomersUp: _vm.SharedSustomersUp,
        },
        model: {
          value: _vm.staffResultUp,
          callback: function ($$v) {
            _vm.staffResultUp = $$v
          },
          expression: "staffResultUp",
        },
      }),
      _vm._v(" "),
      _c("mailList", {
        ref: "staffSelect",
        attrs: {
          radio: _vm.radio,
          condition: _vm.condition,
          visible: _vm.mailListVisible,
        },
        on: {
          "update:visible": function ($event) {
            _vm.mailListVisible = $event
          },
          maillistRadio: _vm.maillistRadio,
        },
        model: {
          value: _vm.staffResult,
          callback: function ($$v) {
            _vm.staffResult = $$v
          },
          expression: "staffResult",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pct50" }, [
      _c("p", { staticClass: "f16" }, [_vm._v("0.00元")]),
      _vm._v(" "),
      _c(
        "p",
        {
          staticClass: "f14 blueFont",
          staticStyle: { height: "20px", "line-height": "20px" },
        },
        [_vm._v("退款金额")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }