import "core-js/modules/es6.number.constructor";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/web.dom.iterable";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { School, Majorlist, getIttnxm, FollwupAdd, Customerinfo, getLabelpeer, FollwupIndex as _FollwupIndex, getEntrance, BrochuresList as _BrochuresList, MyShareSetshare, MyCustomerIndex, MyCustomerUpdate, getCustomerLevel, getGraduationMajor, agentFlowerNameList, MyCustomerRegression, MyCustomerCustomerlog, MyCustomerTransfoll, MyCustomerTransfer, orderlist, SubOrderDetail } from '@/api/ceshi_xiugai/whole';
import mailListEs from '@/components/StaffSelect/mailListRadioEs';
import { getUnfollow } from '@/api/PublicAPI';
import mailListUp from '@/components/StaffSelect/mailListRadioUp';
import mailList from '@/components/StaffSelect/mailListRadio';
import { getInfo } from '@/api/login';
import { getStudyMajor as _getStudyMajor, getSchoolLevels as _getSchoolLevels } from '@/api/ceshi_xiugai/order';
export default {
  components: {
    mailListEs: mailListEs,
    mailListUp: mailListUp,
    mailList: mailList
  },
  data: function data() {
    return {
      sum_money: '',
      //成交金额
      staffResultUp: [],
      //转移跟进人
      radioUp: false,
      //转移跟进人
      mailListVisibleUp: false,
      Unfollow: '',
      //下次会发条数
      get_type: 0,
      Unfollowshow: false,
      //禁止改
      disable: true,
      wxdisable: true,
      mo1disable: false,
      mo2disable: false,
      noteApp: true,
      customer_id: this.$route.query.Cid,
      customer_data_id: this.$route.query.CData_id,
      editRole: this.$route.query.editRole,
      pageTop: this.$route.query.page,
      pagesizeTop: this.$route.query.pagesize,
      keyTop: this.$route.query.key,
      Search: this.$route.query.Search,
      Student: this.$route.query.customer_level,
      list_typeEs: this.$route.query.list_type,
      activeNames: ['0'],
      //默认收齐状态
      pickerOptions: {
        disabledDate: function disabledDate(v) {
          return v.getTime() < new Date().getTime() - 86400000;
        }
      },
      staffResultEs: [],
      radioEs: false,
      mailListVisibleEs: false,
      reverse: true,
      isNullCphone: false,
      isNullwxnum: false,
      isNullCphonetwo: false,
      dialogFormVisible: false,
      isNullCphonethree: false,
      isusertype: false,
      loading: false,
      radios: false,
      show2: false,
      show3: false,
      regression: true,
      //公海、私海
      mailListVisible: false,
      //创建人通讯录开关
      staffResult: [],
      //共享通讯录选取的值
      condition: [],
      page: 1,
      //页码
      pagesize: 15,
      //条数
      isusertypeName: '查看全部',
      journal: [],
      enteredBy: '',
      Intended: '',
      //意向专业name值
      General: '',
      //意向简章name值
      ins: '',
      //意向院校name值
      IntendedStation: [],
      //意向专业中转站
      IntendedMajor: [],
      GeneralStation: [],
      //意向简章中转站
      GeneralRules: [],
      instituStation: [],
      //意向院校中转站
      institu: [],
      flowerNumber: [],
      //花名
      entranceTextEs: '',
      flowerNumberStation: [],
      //花名中转站
      detailForm: {
        name: '',
        //名称
        flower_name: '',
        //花名
        flowerNameId: 0,
        //花名id
        category: '',
        //类别
        agent_num: '',
        //花名码
        Telephone: '',
        //电话1
        cphoneAdress: '',
        //归属地1
        Telephone2: '',
        //电话2
        cphoneAdress2: '',
        //归属地2
        Telephone3: '',
        //电话3
        cphoneAdress3: '',
        //归属地3
        mailbox: '',
        //邮箱
        QQ: '',
        //qq
        extensionName: '',
        //推广名称
        extensionAddress: '',
        //推广地址
        remarks: '',
        //备注
        appended: '',
        WeChat: '',
        //微信
        educationText: '',
        //当前学历
        areaOfInterest: '',
        //意向地区
        projectTextId: '',
        //报考项目id
        projectText: '',
        //报考项目
        GraduationMajor: '',
        //毕业专业
        IntendedMajor: [],
        //意向专业
        GeneralRules: [],
        //意向简章
        GraduatedFrom: '',
        //毕业院校
        institu: [],
        //意向院校所选的值
        budget: '',
        //预算
        sex: '',
        //性别
        entranceText: '',
        //预计入学id
        entranceTexts: '',
        //预计入学val
        post: '',
        //岗位
        achievements: '',
        //学术成绩
        Age: '',
        //年龄
        Landline: '',
        //座机
        occupation: '',
        //单位
        language: '',
        //语言成绩
        industry: '',
        //行业
        Passport: '',
        //护照号
        // communication: '', //发起沟通的网址
        hobby: '',
        //性格
        address: '',
        //详细地址
        labelpeer: [],
        //客户标签
        Student: [] //学员级别
      },
      baokaixiangmu: '',
      educationText: '',
      detailFormShow: {
        cname: true,
        intentionally: true,
        cphone: true,
        agent_name: true,
        area: true,
        noteAppEs: true,
        adress: true,
        note: true,
        wxnum: true,
        telephone: true,
        cemail: true,
        qq: true,
        communication_name: true,
        communication_website: true,
        cphonetwo: true,
        cphonethree: true,
        ittnzy: true,
        ittnzyShow: true,
        ittnxl: true,
        ittnxlShow: true,
        ittnyx: true,
        ittnyxShow: true,
        ittnxm: true,
        ittnxmShow: true,
        ittngj: true,
        budget: true,
        xuel: true,
        graduate: true,
        graduatezy: true,
        xueshuchengji: true,
        yuyanchengji: true,
        timeline: true,
        sex: true,
        age: true,
        station: true,
        industry: true,
        character: true,
        occupation: true,
        hzID: true,
        hobby: true,
        tolink: true,
        next_follow: true,
        groupName: true,
        groupNameShow: true
      },
      form: {
        next_follow: '',
        //下次回访日期
        theme: '',
        typese: '1'
      },
      rules: {
        // theme: [{ required: true, message: '主题不能为空', trigger: 'blur' }],
      },
      flower_Array: [],
      //花名
      Intendeds: [],
      //意向专业
      GenalRules: [],
      //意向简章
      chatmodes: [],
      //意向院校
      activities: [],
      // 跟进记录
      projects: [],
      //报考项目
      entrance: [],
      //入学时间
      educations: [],
      //当前学历
      StudentLevel: [],
      //学员级别
      customer: [],
      //客户标签
      transferorid: '',
      //共享人的userid
      radio: false,
      tableData: [],
      //成交订单列表
      total: 0,
      //总条数
      charge_type: '',
      //用户对应组织架构类型
      orderPagesize: 10,
      orderPage: 1,
      info: {},
      imgPay: [],
      srcList: [],
      study_majorArr: [],
      //学习专业
      schoolLevels: [] //院校层次
    };
  },
  filters: {
    pay_purpose: function pay_purpose(num) {
      if (num == 1) {
        return '报名费';
      } else if (num == 2) {
        return '定金';
      } else if (num == 3) {
        return '学费';
      } else if (num == 4) {
        return '资料费';
      } else if (num == 5) {
        return '语言培训费';
      } else if (num == 6) {
        return '普通留学申请费';
      }
    },
    periodization: function periodization(num) {
      if (num == 1) {
        return '一次性付款';
      } else if (num == 2) {
        return '分两次全款';
      } else if (num == 3) {
        return '分三次全款';
      } else if (num == 4) {
        return '分四次全款';
      }
    },
    periodization_set: function periodization_set(num) {
      if (num == 1) {
        return '一次性全款';
      } else if (num == 2) {
        return '第二次付款';
      } else if (num == 3) {
        return '第三次付款';
      } else if (num == 4) {
        return '第四次付款';
      }
    }
  },
  created: function created() {
    var _this = this;
    getInfo().then(function (res) {
      _this.charge_type = res.data.charge_type;
    });
    this.getStudyMajor();
    this.getSchoolLevels();
  },
  mounted: function mounted() {
    this.GetDetails(this.customer_id, this.customer_data_id);
    this.education();
    this.Customerlog(this.customer_id, this.customer_data_id);
    this.getoneinfo();
  },
  methods: {
    getoneinfo: function getoneinfo() {
      var _this2 = this;
      SubOrderDetail({
        id: this.$route.query.id,
        customer_id: this.$route.query.cid
      }).then(function (respomse) {
        _this2.info = respomse.data;
        if (respomse.data.picture_list) {
          _this2.imgPay = respomse.data.picture_list;
          for (var i = 0; i < _this2.imgPay.length; i++) {
            _this2.srcList.push(_this2.imgPay[i].url);
          }
        }
      });
    },
    getStudyMajor: function getStudyMajor() {
      var _this3 = this;
      _getStudyMajor().then(function (res) {
        _this3.study_majorArr = res.data;
      });
    },
    getSchoolLevels: function getSchoolLevels() {
      var _this4 = this;
      _getSchoolLevels().then(function (res) {
        _this4.schoolLevels = res.data;
      });
    },
    // 删除空数组方法
    removeByValue: function removeByValue(arr, attr, value) {
      var index = 0;
      for (var i in arr) {
        if (arr[i][attr] == value) {
          index = i;
          break;
        }
      }
      arr.splice(index, 1);
    },
    addOrder: function addOrder() {
      var data = {
        customer_id: this.customer_id,
        customer_data_id: this.customer_data_id
        // name:this.detailForm.name,//客户名称
        // flower_name:this.detailForm.flower_name,//学员来源
        // category:this.detailForm.category,//客户类型
        //客户创建人
        //成交顾问
        //数据所属部门
        //客户总类
      };
      this.$router.push({
        path: '/orderInfo',
        query: data
      });
    },
    GetDetails: function GetDetails(customer_id, customer_data_id) {
      var _this5 = this;
      //编辑获取详情
      Customerinfo({
        customer_id: customer_id,
        customer_data_id: customer_data_id
      }).then(function (res) {
        var data = res.data;
        _this5.customer_id = res.data.customer_id;
        if (data.create_id != _this5.$store.getters.id) {
          _this5.noteApp = true;
        }
        var money = '' + data.sum_money;
        if (money.indexOf(".") != -1) {
          _this5.sum_money = money;
        } else {
          _this5.sum_money = money + ".00";
        }
        //学员来源(花名)不可修改
        // if (data.source_client_info == '') {
        //     this.disable = true;
        // }
        _this5.disable = data.source_client_id !== '' ? true : false;
        _this5.detailForm.appended = data.create_notes;
        //意向专业
        _this5.detailForm.IntendedMajor = [];
        _this5.IntendedMajor = [];
        data.ittnzy.forEach(function (item) {
          _this5.IntendedMajor.push(item);
          _this5.Intended = item.title.toString();
          _this5.detailForm.IntendedMajor.push(item.id);
        });
        _this5.majorDedMaj();
        //意向简章
        _this5.GeneralRules = [];
        _this5.detailForm.GeneralRules = [];
        data.ittnjz.forEach(function (item) {
          _this5.GeneralRules.push(item);
          _this5.General = item.title.toString();
          _this5.detailForm.GeneralRules.push(item.id);
        });
        _this5.BrochuresList();
        // 意向院校
        _this5.institu = [];
        _this5.detailForm.institu = [];
        data.ittnyx.forEach(function (item) {
          _this5.institu.push(item);
          _this5.ins = item.title.toString();
          _this5.detailForm.institu.push(item.id);
        });
        _this5.SchoolDer();
        // 花名
        _this5.flowerNumber = [];
        // this.detailForm.flowerNameId = item.id
        if (data.source_client_id !== '') {
          data.source_client_info.forEach(function (item) {
            _this5.flowerNumber.push(item);
            _this5.detailForm.flowerNameId = item.id;
            _this5.detailForm.flower_name = item.flower_name;
            _this5.detailForm.category = item.leibie;
            _this5.detailForm.agent_num = item.number;
          });
        } else {
          _this5.detailForm.flowerNameId = '';
        }
        _this5.flowerArray();
        // 公海、私海
        _this5.regression = data.sea_type == 0 ? true : false;
        var khbq = data.labelpeer.split(',').map(function (res) {
          return res;
        });
        _this5.detailForm.name = data.cname;
        _this5.detailForm.Telephone = data.mobile;
        _this5.detailForm.cphoneAdress = data.mobile_address;
        _this5.detailForm.Telephone2 = data.mobile1;
        _this5.detailForm.cphoneAdress2 = data.mobile1_address;
        _this5.detailForm.Telephone3 = data.mobile2;
        _this5.detailForm.cphoneAdress3 = data.mobile2_address;
        _this5.detailForm.mailbox = data.cemail;
        _this5.detailForm.QQ = data.qq;
        _this5.detailForm.remarks = data.notes;
        _this5.detailForm.WeChat = data.wxnum;
        _this5.detailForm.extensionName = data.communication_name;
        _this5.detailForm.extensionAddress = data.communication_website;
        if (data.wxnum == '') {
          _this5.wxdisable = false;
        } else {
          _this5.wxdisable = true;
        }
        if (data.mobile1 == '') {
          _this5.mo1disable = false;
        } else {
          _this5.mo1disable = true;
          _this5.show2 = true;
        }
        if (data.mobile2 == '') {
          _this5.mo2disable = false;
        } else {
          _this5.mo2disable = true;
          _this5.show3 = true;
        }
        getGraduationMajor().then(function (res) {
          res.data.map(function (item) {
            item.id = String(item.id);
            if (item.id == String(data.graduation_xueli)) {
              _this5.educationText = item.value;
            }
          });
        });
        if (data.graduation_xueli == '0') {
          _this5.detailForm.educationText = '';
        } else {
          _this5.detailForm.educationText = String(data.graduation_xueli);
        }
        _this5.detailForm.areaOfInterest = data.ittngj;
        getIttnxm().then(function (res) {
          res.data.map(function (item) {
            item.id = String(item.id);
            if (item.id == data.ittnxl) {
              _this5.baokaixiangmu = item.value;
            }
          });
        });
        if (data.ittnxl == '0') {
          _this5.detailForm.projectTextId = '';
        } else {
          _this5.detailForm.projectTextId = String(data.ittnxl);
        }
        _this5.detailForm.GraduationMajor = data.graduation_major;
        _this5.detailForm.GraduatedFrom = data.graduate_school;
        _this5.detailForm.budget = data.budget;
        _this5.detailForm.sex = String(data.sex);
        if (data.entrance == '0') {
          _this5.detailForm.entranceTexts = '';
        } else {
          _this5.detailForm.entranceTexts = String(data.entrance); //预计入学
        }
        getEntrance().then(function (res) {
          //入学时间
          res.data.map(function (item) {
            item.id = String(item.id);
            if (item.id == _this5.detailForm.entranceText) {
              _this5.entranceTextEs = item.value;
              _this5.detailForm.entranceText = item.value;
            }
          });
          _this5.entrance = res.data;
        });
        _this5.detailForm.post = data.post;
        _this5.detailForm.achievements = data.academic_achievements;
        if (data.age == '0') {
          _this5.detailForm.Age = '';
        } else {
          _this5.detailForm.Age = String(data.age);
        }
        _this5.detailForm.Landline = data.telephone;
        _this5.detailForm.occupation = data.occupation;
        _this5.detailForm.language = data.language_achievement;
        _this5.detailForm.industry = data.industry;
        _this5.detailForm.Passport = data.passport_number;
        // this.detailForm.communication = data.communication_website;
        _this5.detailForm.address = data.detailed_address;
        _this5.detailForm.labelpeer = khbq;
        _this5.detailForm.Student = data.customer_level;
      });
    },
    // 编辑跳转页面
    editCustomer: function editCustomer() {
      this.$router.push({
        path: '/addCustomer?pid=2&customer_id=' + this.customer_id + '&customer_data_id=' + this.customer_data_id
      });
    },
    internationalWaters: function internationalWaters() {
      var _this6 = this;
      //回归公海
      this.$confirm('请确认是否将当前客户回归到公海？', '回归公海', {
        confirmButtonText: '继续回归',
        cancelButtonText: '取消'
      }).then(function () {
        MyCustomerRegression({
          customer_data_ids: _this6.customer_data_id
        }).then(function (res) {
          _this6.regression = false;
          _this6.$notify({
            title: '成功',
            message: '回归成功',
            type: 'success'
          });
          _this6.next_customer();
        });
      });
    },
    showEs: function showEs(e) {
      //编辑意向专业
      if (!e) {
        this.dblEditCustomer();
      }
    },
    GeneralEs: function GeneralEs(e) {
      //编辑意向简章
      if (!e) {
        this.dblEditCustomer();
      }
    },
    collegesEs: function collegesEs(e) {
      //编辑意向院校
      if (!e) {
        this.dblEditCustomer();
      }
    },
    transfer: function transfer() {
      //共享客户
      this.radioEs = true;
      this.mailListVisibleEs = true;
    },
    //转移跟进人
    followUpPerson: function followUpPerson() {
      this.radioUp = true;
      this.mailListVisibleUp = true;
    },
    //专一创建人
    createPerson: function createPerson() {
      this.radio = true;
      this.mailListVisible = true;
    },
    SharedSustomersUp: function SharedSustomersUp(resultNameArr, result) {
      var _this7 = this;
      //转移跟进人
      this.transferorid = String(result);
      MyCustomerTransfoll({
        customer_data_ids: String(this.customer_data_id),
        user_id: this.transferorid
      }).then(function (res) {
        _this7.$notify({
          title: '提示',
          message: res.data.toString(),
          type: 'success',
          duration: 2000
        });
        _this7.next_customer();
      });
    },
    maillistRadio: function maillistRadio(resultNameArr, result) {
      var _this8 = this;
      //转移创建人
      this.transferor = String(result);
      MyCustomerTransfer({
        customer_ids: String(this.customer_id),
        user_id: this.transferor
      }).then(function (res) {
        _this8.$notify({
          title: '提示',
          message: res.data.toString(),
          type: 'success',
          duration: 3000
        });
        _this8.next_customer();
      });
    },
    staffSelectConfirmEs: function staffSelectConfirmEs(resultNameArr, result) {
      var _this9 = this;
      //选择共享给谁

      this.transferorid = String(result);
      MyShareSetshare({
        customer_ids: String(this.customer_id),
        user_id: this.transferorid
      }).then(function (res) {
        _this9.$notify({
          title: '提示',
          message: res.data.toString(),
          type: 'success',
          duration: 2000
        });
      });
    },
    flowerArray: function flowerArray(val) {
      var _this10 = this;
      //花名
      agentFlowerNameList().then(function (res) {
        var hm = [];
        for (var i in _this10.flowerNumber) {
          if (_this10.flowerNumber[i] != '') {
            hm.push(_this10.flowerNumber[i]);
            for (var key in res.data) {
              hm.push(res.data[key]);
            }
          } else {
            for (var _key in res.data) {
              hm.push(res.data[_key]);
            }
          }
        }
        _this10.removeByValue(hm, 'id', 'null');
        _this10.flower_Array = _this10.dedupe(hm);
      });
    },
    flowerName: function flowerName() {
      var _this11 = this;
      //花名分页
      this.page++;
      agentFlowerNameList({
        page: String(this.page),
        pagesize: '15'
      }).then(function (res) {
        _this11.flower_Array.forEach(function (item) {
          _this11.flowerNumberStation.push(item);
        });
        for (var key in res.data.data) {
          _this11.flowerNumberStation.push(res.data.data[key]);
        }
        _this11.removeByValue(_this11.flowerNumberStation, 'id', 'null');
        var array = _this11.dedupe(_this11.flowerNumberStation);
        _this11.flower_Array = array;
      });
    },
    flowerArrayEs: function flowerArrayEs(val) {
      var _this12 = this;
      //改变花名
      agentFlowerNameList({
        page: String(1),
        pagesize: '15',
        key_word: val
      }).then(function (res) {
        res.data.forEach(function (item) {
          if (val == item.id) {
            _this12.detailForm.category = item.leibie;
            _this12.ruleForm.agent_num = item.number;
          }
        });
        _this12.flower_Array = res.data;
      });
    },
    flower: function flower(e) {
      //编辑花名
      if (!e) {
        this.dblEditCustomer();
      }
    },
    BrochuresList: function BrochuresList() {
      var _this13 = this;
      //意向简章默认
      _BrochuresList().then(function (res) {
        res.data.brochures_list.map(function (item) {
          item.id = String(item.id);
        });
        var zxjz = [];
        for (var i in _this13.GeneralRules) {
          zxjz.push(_this13.GeneralRules[i]);
          for (var key in res.data.brochures_list) {
            zxjz.push(res.data.brochures_list[key]);
          }
        }
        _this13.GenalRules = res.data.brochures_list;
      });
    },
    // GeneralRule(){//意向简章分页
    //     this.page++
    //     BrochuresList({
    //         page:this.page,
    //         pagesize:'15'
    //     }).then(res=> {
    //         res.data.brochures_list.map(item=>{
    //             item.id = String(item.id)
    //         })
    //         this.GenalRules.forEach(item => {
    //             this.GeneralStation.push(item)
    //         });
    //         for (const key in res.data.brochures_list) {
    //             this.GeneralStation.push(res.data.brochures_list[key])
    //         }
    //         let array = this.dedupe(this.GeneralStation)
    //         this.GenalRules= array
    //     })
    // },
    majorDedMaj: function majorDedMaj() {
      var _this14 = this;
      //意向专业默认
      Majorlist().then(function (res) {
        res.data.major_list.map(function (item) {
          item.id = String(item.id);
        });
        var yxzy = [];
        for (var i in _this14.IntendedMajor) {
          yxzy.push(_this14.IntendedMajor[i]);
          for (var key in res.data.major_list) {
            yxzy.push(res.data.major_list[key]);
          }
        }
        _this14.Intendeds = res.data.major_list;
      });
    },
    // IntendedMaj(){//意向专业分页
    //     this.page++
    //     Majorlist({
    //         page:this.page,
    //         pagesize:this.pagesize
    //     }).then(res=> {
    //         res.data.major_list.map(item=>{
    //             item.id = String(item.id)
    //         })
    //         this.Intendeds.forEach(item => {
    //             this.IntendedStation.push(item)
    //         });
    //         for (const key in res.data.major_list) {
    //             this.IntendedStation.push(res.data.major_list[key])
    //         }
    //         let array = this.dedupe(this.IntendedStation)
    //         this.Intendeds = array
    //     })
    // },
    SchoolDer: function SchoolDer() {
      var _this15 = this;
      //意向院校默认
      School().then(function (res) {
        res.data.school_list.map(function (item) {
          item.id = String(item.id);
        });
        var yxyx = [];
        for (var i in _this15.institu) {
          yxyx.push(_this15.institu[i]);
          for (var key in res.data.school_list) {
            yxyx.push(res.data.school_list[key]);
          }
        }
        _this15.chatmodes = res.data.school_list;
      });
    },
    // loadMoreSter(){//意向院校分页
    //     this.page ++
    //     School({
    //         page:this.page,
    //         pagesize:this.pagesize
    //     }).then(res=> {
    //         res.data.school_list.map(item=>{
    //             item.id = String(item.id)
    //         })
    //         this.institu.forEach(item => {
    //             this.instituStation.push(item)
    //         });
    //         res.data.school_list.forEach(item => {
    //             this.instituStation.push(item)
    //         });
    //         let array = this.dedupe(this.instituStation)
    //         this.chatmodes = array
    //     })
    // },
    prev_customer: function prev_customer() {
      //上一个客户
      if (this.pageTop == 1) {
        if (this.keyTop == 0) {
          this.$notify({
            title: '警告',
            message: '这是第一个客户',
            type: 'warning'
          });
        } else {
          this.keyTop--;
          this.MyCustomers();
        }
      } else {
        if (this.keyTop == 0) {
          this.pageTop--;
          this.keyTop = this.pagesizeTop - 1;
          this.MyCustomers();
        } else {
          this.keyTop--;
          this.MyCustomers();
        }
      }
    },
    next_customer: function next_customer() {
      var _this16 = this;
      //下一个客户
      if (this.list_typeEs == 1 || this.list_typeEs == 0) {
        var result = localStorage.getItem('resultES');
        var resultES = JSON.parse(result);
        resultES.page = this.pageTop;
        resultES.pagesize = this.pagesizeTop;
        MyCustomerIndex(resultES).then(function (res) {
          var data = res.data.data;
          if (_this16.keyTop == _this16.pagesizeTop - 1) {
            for (var i in data) {
              if (data[i].customer_id == _this16.customer_id) {
                _this16.pageTop++;
                _this16.keyTop = 0;
                break;
              } else {
                _this16.pageTop;
                _this16.keyTop;
              }
            }
            _this16.MyCustomers();
          } else {
            for (var _i in data) {
              if (data[_i].customer_id == _this16.customer_id) {
                _this16.keyTop++;
                break;
              } else {
                _this16.keyTop;
              }
            }
            _this16.MyCustomers();
          }
        });
      } else {
        if (this.keyTop == this.pagesizeTop - 1) {
          this.pageTop++;
          this.keyTop = 0;
          this.MyCustomers();
        } else {
          this.keyTop++;
          this.MyCustomers();
        }
      }
    },
    MyCustomers: function MyCustomers() {
      var _this17 = this;
      //上下客户封装
      var result = localStorage.getItem('resultES');
      var resultES = JSON.parse(result);
      resultES.page = String(this.pageTop);
      resultES.pagesize = this.pagesizeTop;
      MyCustomerIndex(resultES).then(function (res) {
        for (var key in res.data.data) {
          if (key == _this17.keyTop) {
            _this17.$router.push({
              path: '/myCustomerDetail',
              query: {
                Cid: res.data.data[key].customer_id,
                CData_id: res.data.data[key].customer_data_id,
                editRole: 1,
                key: _this17.keyTop,
                page: _this17.pageTop,
                pagesize: _this17.pagesizeTop,
                list_type: _this17.list_typeEs
              }
            });
            _this17.customer_id = res.data.data[key].customer_id;
            _this17.customer_data_id = res.data.data[key].customer_data_id;
            _this17.FollwupIndex(res.data.data[key].customer_id, res.data.data[key].customer_data_id);
            _this17.GetDetails(res.data.data[key].customer_id, res.data.data[key].customer_data_id);
            _this17.Customerlog(res.data.data[key].customer_id, res.data.data[key].customer_data_id);
          }
        }
        if (_this17.keyTop > res.data.data.length - 1) {
          _this17.$notify({
            title: '警告',
            message: '这是最一个客户',
            type: 'warning'
          });
          return _this17.keyTop = res.data.data.length - 1;
        }
      });
    },
    switchs: function switchs() {
      //操作日志开关
      if (this.isusertype == false) {
        this.isusertype = true;
        this.isusertypeName = '收起内容';
      } else {
        this.isusertype = false;
        this.isusertypeName = '查看全部';
      }
    },
    Customerlog: function Customerlog(log, dataId) {
      var _this18 = this;
      //操作日志
      MyCustomerCustomerlog({
        customer_id: log,
        customer_data_id: dataId
      }).then(function (res) {
        _this18.journal = res.data.list;
        _this18.enteredBy = res.data.create_info;
      });
    },
    changeShow: function changeShow(value, type) {
      this.changeShowValue(value, type);
    },
    GETPlaceofownership: function GETPlaceofownership(cphone, number) {
      var _this19 = this;
      //手机号验证/查询归属地
      if (!/^1[3456789]\d{9}$/.test(cphone)) {
        this.$notify({
          title: '警告',
          message: '手机号错误',
          type: 'warning'
        });
        this.GetDetails(this.customer_id, this.customer_data_id);
      } else {
        if (this.detailForm.Telephone === this.detailForm.Telephone2) {
          this.$notify({
            title: '警告',
            message: '手机号不能重复',
            type: 'warning'
          });
          this.detailForm.Telephone2 = '';
        } else if (this.detailForm.Telephone2 === this.detailForm.Telephone3) {
          this.$notify({
            title: '警告',
            message: '手机号不能重复',
            type: 'warning'
          });
          this.detailForm.Telephone3 = '';
        } else if (this.detailForm.Telephone === this.detailForm.Telephone3) {
          this.$notify({
            title: '警告',
            message: '手机号不能重复',
            type: 'warning'
          });
          this.detailForm.Telephone3 = '';
        } else {
          GetPhoneAddress({
            phone: cphone
          }).then(function (res) {
            _this19.Show = true;
            if (number == 1) {
              _this19.detailForm.cphoneAdress = res.data.province + res.data.city;
            } else if (number == 2) {
              _this19.detailForm.cphoneAdress2 = res.data.province + res.data.city;
            } else if (number == 3) {
              _this19.detailForm.cphoneAdress3 = res.data.province + res.data.city;
            }
          });
        }
      }
    },
    sendEmail: function sendEmail() {
      //邮箱验证
      var regEmail = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if (this.detailForm.mailbox != '' && !regEmail.test(this.detailForm.mailbox)) {
        this.$message.error('邮箱格式不正确');
        this.detailForm.mailbox = '';
      }
    },
    QQverification: function QQverification(val) {
      //QQ号验证
      var regEmail = /^[1-9][0-9]{7,10}$/;
      if (val != '' && !regEmail.test(val)) {
        this.$message.error('QQ号格式不正确');
        this.detailForm.QQ = '';
      }
    },
    Landlines: function Landlines(val) {
      //座机号验证
      var regEmail = /^(0[0-9]{2,3}\-)([2-9][0-9]{4,7})+(\-[0-9]{1,4})?$/;
      if (val != '' && !regEmail.test(val)) {
        this.$message.error('座机号格式不正确');
        this.detailForm.Landline = '';
      }
    },
    displayShow: function displayShow() {
      // 联系电话2和3的显示
      if (this.show2 == false) {
        this.show2 = true;
      } else {
        this.show3 = true;
      }
    },
    mobilePhone: function mobilePhone(number) {
      //隐藏清空电话
      if (number == 2) {
        this.show2 = false;
        this.detailForm.Telephone3 = '';
        this.detailForm.cphoneAdress3 = '';
      } else {
        this.show3 = false;
        this.detailForm.Telephone3 = '';
        this.detailForm.cphoneAdress3 = '';
      }
    },
    education: function education() {
      var _this20 = this;
      //进入页面加载的接口
      getGraduationMajor().then(function (res) {
        //当前学历
        res.data.map(function (item) {
          item.id = String(item.id);
        });
        _this20.educations = res.data;
      });
      getIttnxm().then(function (res) {
        //报考项目
        res.data.map(function (item) {
          item.id = String(item.id);
        });
        _this20.projects = res.data;
      });

      // getEntrance().then(res => {
      //     //入学时间
      //     res.data.map(item => {
      //         item.id = String(item.id);
      //     });
      //     this.entrance = res.data;
      // });
      getLabelpeer().then(function (res) {
        //客户标签
        res.data.map(function (item) {
          item.id = String(item.id);
        });
        _this20.customer = res.data;
      });
      getCustomerLevel().then(function (res) {
        res.data.map(function (e) {
          e.label = e.name;
          e.value = e.id;
        });
        for (var item in res.data) {
          if (res.data[item].children != undefined) {
            res.data[item].children.map(function (dats) {
              dats.label = dats.name;
              dats.value = dats.id;
            });
          }
        }
        _this20.StudentLevel = res.data;
      });
      this.FollwupIndex(this.customer_id, this.customer_data_id);
    },
    dedupe: function dedupe(array) {
      //json去重数组方法
      var d = [];
      var hash = {};
      d = array.reduce(function (item, next) {
        hash[next.id] ? '' : hash[next.id] = true && item.push(next);
        return item;
      }, []);
      return d;
    },
    educationSe: function educationSe(val) {
      var _this21 = this;
      //当前学历
      this.detailForm.educationText = val;
      getGraduationMajor().then(function (res) {
        res.data.map(function (item) {
          item.id = String(item.id);
          if (item.id == val) {
            _this21.educationText = item.value;
          }
        });
      });
      this.dblEditCustomer();
    },
    projectSe: function projectSe(val) {
      var _this22 = this;
      //报考项目
      this.detailForm.projectTextId = val;
      getIttnxm().then(function (res) {
        res.data.map(function (item) {
          item.id = String(item.id);
          if (item.id == val) {
            _this22.detailForm.projectText = item.value;
            _this22.dblEditCustomer();
          }
        });
      });
    },
    detailFormSex: function detailFormSex(val) {
      //男女
      this.detailForm.sex = val;
      this.dblEditCustomer();
    },
    detailFormEntrance: function detailFormEntrance(val) {
      var _this23 = this;
      //入学时间
      this.detailForm.entranceTexts = val;
      getEntrance().then(function (res) {
        res.data.map(function (item) {
          item.id = String(item.id);
          if (item.id == val) {
            _this23.detailForm.entranceTextEs = item.value;
          }
        });
      });
      this.dblEditCustomer();
    },
    handleChange: function handleChange(val) {
      if (val == '') {
        this.detailForm.Student = '';
      } else {
        this.detailForm.Student = val[1] == undefined ? val[0] : val[1];
        this.dblEditCustomer();
      }
    },
    getTimestamp: function getTimestamp(time) {
      //把时间日期转成时间戳
      return new Date(time).getTime() / 1000;
    },
    FollwupIndex: function FollwupIndex(customer_id, customer_data_id) {
      var _this24 = this;
      this.activities = '';
      _FollwupIndex({
        id: Number(customer_id),
        customer_data_id: Number(customer_data_id)
      }).then(function (res) {
        res.data.map(function (res) {
          if (res.type == 1) {
            res.type = '电话';
          } else if (res.type == 2) {
            res.type = 'QQ';
          } else if (res.type == 3) {
            res.type = '微信';
          } else if (res.type == 4) {
            res.type = '面聊';
          }
          res.next_time = String(res.next_time);
        });
        _this24.activities = res.data;
      });
    },
    NewFollowupRecordSure: function NewFollowupRecordSure() {
      var _this25 = this;
      //添加跟进
      if (this.form.typese == '') {
        this.$message({
          type: 'warning',
          message: '请选择跟进类型'
        });
      } else if (this.form.theme == '') {
        this.$message({
          type: 'warning',
          message: '请填写内容'
        });
      } else {
        FollwupAdd({
          customer_id: Number(this.customer_id),
          customer_data_id: Number(this.customer_data_id),
          content: this.form.theme,
          type: Number(this.form.typese),
          next_time: this.form.next_follow.toString().substr(0, 10)
        }).then(function (res) {
          //入学时间
          _this25.FollwupIndex(_this25.customer_id, _this25.customer_data_id);
          _this25.$notify({
            title: '成功',
            message: '新增跟进成功',
            type: 'success'
          });
          _this25.form.theme = '';
          _this25.form.typese = '1';
          _this25.form.next_follow = '';
          _this25.dialogFormVisible = false;
          //this.getTimestamp(
        });
      }
    },
    dblEditCustomer: function dblEditCustomer() {
      var _this26 = this;
      //编辑信息
      var detailForm = this.detailForm;
      if (detailForm.name == '') {
        this.$message({
          type: 'warning',
          message: '客户名称输入有误'
        });
        this.GetDetails(this.customer_id, this.customer_data_id);
      } else {
        var data = {
          customer_id: this.customer_id,
          customer_data_id: this.customer_data_id,
          cname: detailForm.name,
          //客户名称
          source_client_id: String(detailForm.flowerNameId == undefined ? '' : detailForm.flowerNameId),
          //花名
          mobile: detailForm.Telephone,
          //手机号
          mobile1: detailForm.Telephone2,
          //手机号1
          mobile2: detailForm.Telephone3,
          //手机2
          qq: detailForm.QQ,
          //qq号
          wxnum: detailForm.WeChat,
          //微信号
          communication_name: detailForm.extensionName,
          //推广名称
          communication_website: detailForm.extensionAddress,
          cemail: detailForm.mailbox,
          //邮箱
          sex: detailForm.sex,
          //性别 0男 1女
          age: detailForm.Age,
          //年龄
          entrance: detailForm.entranceTexts,
          //预计入学
          post: detailForm.post,
          //岗位
          company: detailForm.occupation,
          //单位
          academic_achievements: detailForm.achievements,
          //学术成绩
          passport_number: detailForm.Passport,
          //护照号码
          language_achievement: detailForm.language,
          //语言成绩
          // communication_website: detailForm.communication, //发起沟通网址
          detailed_address: detailForm.address,
          //地址
          character: detailForm.hobby,
          //性格
          customer_level: detailForm.Student,
          //学员级别
          mobile_address: detailForm.cphoneAdress,
          //手机归属地1
          mobile1_address: detailForm.cphoneAdress2,
          //手机归属地2
          mobile2_address: detailForm.cphoneAdress3,
          //手机归属地3
          graduation_major: detailForm.GraduationMajor,
          //毕业专业
          graduate_school: detailForm.GraduatedFrom,
          //毕业院校
          ittngj: detailForm.areaOfInterest,
          //意向地区
          ittnzy: String(detailForm.IntendedMajor),
          //意向专业id 1,2,3
          ittnyx: String(detailForm.institu),
          //意向院校id 1,2,3
          ittnxm: detailForm.projectTextId,
          //报考项目id
          ittnjz: String(detailForm.GeneralRules),
          //意向简章id 1,2,3
          budget: detailForm.budget,
          //预算
          labelpeer: String(detailForm.labelpeer),
          //客户标签 1,2,3
          notes: detailForm.remarks,
          //备注
          telephone: detailForm.Landline,
          //座机
          occupation: detailForm.occupation,
          //职业
          industry: detailForm.industry,
          //行业
          create_notes: detailForm.appended,
          //创建附注
          graduation_xueli: detailForm.educationText
        };
        MyCustomerUpdate(data).then(function (res) {
          _this26.GetDetails(_this26.customer_id, _this26.customer_data_id);
          _this26.$notify({
            title: '成功',
            message: '编辑成功',
            type: 'success',
            duration: 2000
          });
        });
      }
    },
    changeShowValue: function changeShowValue(value, type) {
      //悬浮状态
      if (Number(this.editRole)) {
        if (type) {
          var showArr = ['intentionally', 'agent_name', 'xuel', 'timeline', 'adress'];
          var showNum = 0;
          for (var key in showArr) {
            if (this.detailFormShow[showArr[key]] == false) {
              showNum = 1;
            }
          }
          for (var _key2 in this.detailFormShow) {
            if (_key2 == value) {
              this.detailFormShow[_key2] = false;
            } else if (showNum == 1) {
              this.detailFormShow[_key2] = true;
            }
          }
        } else {
          for (var _key3 in this.detailFormShow) {
            if (_key3 == value) {
              this.detailFormShow[_key3] = true;
            }
          }
        }
      }
    },
    //zml下次回访条数
    getUnfollowmsg: function getUnfollowmsg() {
      var _this27 = this;
      var next_time = this.form.next_follow.toString().substr(0, 10);
      getUnfollow({
        next_time: next_time
      }).then(function (res) {
        if (res.data == '') {
          _this27.Unfollowshow = false;
        } else {
          _this27.Unfollowshow = true;
          _this27.Unfollow = res.data;
        }
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      //每页 ${val} 条
      // this.getList(this.page, val);
      // this.pagesize = val;
    },
    handleCurrentChange: function handleCurrentChange(val) {
      //当前页
      // this.getList(val, this.pagesize);
      // this.page = val;
    },
    handleClick: function handleClick(tab, event) {
      var _this28 = this;
      if (tab.label == '成交订单') {
        var data = {
          customer_id: Number(this.customer_id),
          customer_data_id: Number(this.customer_data_id),
          pagesize: this.orderPagesize,
          page: this.orderPage
        };
        orderlist(data).then(function (res) {
          _this28.tableData = res.data.data;
          _this28.total = res.data.total;
        });
      }
    },
    orderDetails: function orderDetails(value) {
      // this.$router.push({ path: '/orderDetails' });
    }
  }
};